import { NavbarLink, NavbarLinkList } from './types'

export function isNavList(value: any): value is NavbarLinkList {
    return Boolean(value.list)
}

export function isNavLink(value: any): value is NavbarLink {
    return Boolean(value.url)
}

export const navbarLinks: (NavbarLink | NavbarLinkList)[] = [
    {
        text: 'Публикации',
        list: [
            {
                text: 'Статьи',
                url: '/posts',
            },
            {
                text: 'Выпуски',
                url: '/front-pages',
            },
        ],
    },
    {
        text: 'Ленты',
        list: [
            {
                text: 'Создать новую ленту',
                url: '/feeds/create',
            },
            {
                text: 'Все ленты',
                url: '/feeds',
            },
        ],
    },
    {
        text: 'Администрирование',
        list: [
            {
                text: 'Пользователи',
                url: '/users',
            },
        ],
    },
]
