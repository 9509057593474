import { List, ListSubheader } from '@mui/material'
import { VFC } from 'react'
import { NavigationProfile, useCurrentUser } from 'entities/viewer'
import { isNavList, navbarLinks } from '../../lib'
import { NavbarLink } from '../link'
import { StyledDrawer } from './styles'

export const Navbar: VFC = () => {
    const { data: profile } = useCurrentUser()

    return (
        <StyledDrawer open variant='permanent'>
            <NavigationProfile {...profile} />

            <List>
                {navbarLinks.map((link) =>
                    isNavList(link) ? (
                        <List
                            key={link.text}
                            subheader={
                                <ListSubheader> {link.text}</ListSubheader>
                            }
                        >
                            {link.list.map(({ text, url }) => (
                                <NavbarLink key={text} text={text} url={url} />
                            ))}
                        </List>
                    ) : (
                        <NavbarLink
                            key={link.text}
                            text={link.text}
                            url={link.url}
                        />
                    ),
                )}
            </List>
        </StyledDrawer>
    )
}
