import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import type { ChangeEvent, VFC } from 'react'

type Props = {
    data: Array<{
        id: string
        label?: string
    }>
    onSelectAll?: (event: ChangeEvent<HTMLInputElement>) => void
    selectedCount: number
    itemsCount: number
}

export const UsersTableHead: VFC<Props> = ({
    data,
    onSelectAll,
    selectedCount,
    itemsCount,
}) => (
    <TableHead>
        <TableRow>
            <TableCell padding='checkbox'>
                <Checkbox
                    checked={itemsCount > 0 && selectedCount === itemsCount}
                    color='primary'
                    indeterminate={
                        selectedCount > 0 && selectedCount < itemsCount
                    }
                    inputProps={{
                        'aria-label': 'выбрать всех пользователей',
                    }}
                    onChange={onSelectAll}
                />
            </TableCell>
            {data.map((headCell) => (
                <TableCell key={headCell.id} align='left'>
                    {headCell.label}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
)
