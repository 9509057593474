import { FC } from 'react'
import { FrontPageManager } from 'features/front-page-manager'
import { Wrapper } from './styled'
import { Props } from './types'

export const FrontPages: FC<Props> = () => (
    <Wrapper>
        <FrontPageManager />
    </Wrapper>
)
