import Box from '@mui/material/Box'
import type { Components } from 'react-markdown'

export const ListItem: Components['li'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <Box
        children={children}
        component='li'
        sx={{ lineHeight: '2' }}
        {...props}
    />
)
