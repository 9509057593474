import { FC } from 'react'
import { UserProfile } from 'features/user-profile'
import { Wrapper } from './styled'
import { Props } from './types'

export const Profile: FC<Props> = () => (
    <Wrapper>
        <UserProfile />
    </Wrapper>
)
