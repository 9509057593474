import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Button, Paper, Snackbar, TextField } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, ref, string } from 'yup'
import { useChangePassword } from 'features/user-profile/model/__generated__/ChangePassword'

type FormProps = {
    currentPassword: string
    password: string
    cpassword: string
}

const password = () =>
    string()
        .required('Пароль не может быть пустым')
        .min(8, 'Пароль должен быть не менее 8 символов')
        .test(
            'minLowerCase',
            'Пароль должен содержать минимум 1 букву в нижнем регистре',
            (value) => (value?.match(/[a-z]/g) || []).length >= 1,
        )
        .test(
            'minUpperCase',
            'Пароль должен содержать минимум 1 букву в верхнем регистре',
            (value) => (value?.match(/[A-Z]/g) || []).length >= 1,
        )
        .test(
            'minNumber',
            'Пароль должен содержать минимум 1 цифру',
            (value) => (value?.match(/[0-9]/g) || []).length >= 1,
        )
        .test(
            'minSymbols',
            'Пароль должен содержать минимум 1 специальный символ',
            (value) => (value?.match(/[^a-zA-Z0-9\s]/g) || []).length >= 1,
        )

const formSchema = object({
    currentPassword: string().required('Пароль не может быть пустым'),
    password: password(),
    cpassword: password().oneOf([ref('password')], 'Пароли не совпадают'),
})

export const UserChangePassword = () => {
    const [changePassword, { error }] = useChangePassword()

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<FormProps>({
        mode: 'onSubmit',
        resolver: yupResolver(formSchema),
    })

    const [showSnackbar, setShowSnackbar] = useState(false)
    const handleCloseSnackbar = (_: any, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setShowSnackbar(false)
        reset()
    }

    const onSubmit = (data: FormProps) => {
        changePassword({
            variables: {
                data: {
                    password: data.currentPassword,
                    newPassword: data.password,
                },
            },
        }).finally(() => setShowSnackbar(true))
    }

    return (
        <Paper component='form' sx={{ padding: '24px' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                }}
            >
                <TextField
                    {...register('currentPassword')}
                    fullWidth
                    autoComplete='current-password'
                    error={Boolean(errors.currentPassword)}
                    helperText={errors.currentPassword?.message}
                    label='Текущий пароль'
                    type='password'
                />
                <TextField
                    {...register('password')}
                    fullWidth
                    autoComplete='new-password'
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    label='Новый пароль'
                    sx={{ marginTop: '24px' }}
                    type='password'
                />
                <TextField
                    {...register('cpassword')}
                    fullWidth
                    autoComplete='new-password'
                    error={Boolean(errors.cpassword)}
                    helperText={errors.cpassword?.message}
                    label='Подтвердить пароль'
                    sx={{ marginTop: '24px' }}
                    type='password'
                />
                <Button
                    sx={{ marginTop: '24px' }}
                    type='submit'
                    onClick={handleSubmit(onSubmit)}
                >
                    Сохранить
                </Button>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                open={showSnackbar}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    severity='success'
                    sx={{ minWidth: '320px', width: '100%' }}
                >
                    Пароль обновлён
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                open={!!error && showSnackbar}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    severity='error'
                    sx={{ minWidth: '320px', width: '100%' }}
                >
                    Ошибка: {error?.message}
                </Alert>
            </Snackbar>
        </Paper>
    )
}
