// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ComponentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ComponentsQuery = { components: Array<{ uid: any, name: string, configuration: { h: number, w: number, type: string } }> };


export const ComponentsQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ComponentsQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"components"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"configuration"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"h"}},{"kind":"Field","name":{"kind":"Name","value":"w"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useComponentsQuery__
 *
 * To run a query within a React component, call `useComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComponentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useComponentsQuery(baseOptions?: Apollo.QueryHookOptions<ComponentsQuery, ComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ComponentsQuery, ComponentsQueryVariables>(ComponentsQueryDocument, options);
      }
export function useComponentsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ComponentsQuery, ComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ComponentsQuery, ComponentsQueryVariables>(ComponentsQueryDocument, options);
        }
export type ComponentsQueryHookResult = ReturnType<typeof useComponentsQuery>;
export type ComponentsQueryLazyQueryHookResult = ReturnType<typeof useComponentsQueryLazyQuery>;
export type ComponentsQueryQueryResult = Apollo.QueryResult<ComponentsQuery, ComponentsQueryVariables>;