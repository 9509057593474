export const tableHeadCells = [
    {
        id: 'name',
        label: 'Имя',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'role',
        label: 'Роли',
    },
    {
        id: 'subscribe',
        label: 'Подписка',
    },
    {
        id: 'status',
        label: 'Статус',
    },
    {
        id: 'actions',
        label: '',
    },
]
