import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from '@mui/material'
import { VFC } from 'react'
import { DraftItemProps } from './types'

export const DraftListItem: VFC<DraftItemProps> = ({ description, title }) => (
    <Box>
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Typography gutterBottom component='div' variant='h5'>
                    {title}
                </Typography>
                <Typography color='text.secondary' variant='body2'>
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button href='/draft-edit/' size='small'>
                    Редактировать
                </Button>
                <Button size='small'>Удалить</Button>
            </CardActions>
        </Card>
    </Box>
)
