import { useNavigate } from '@reach/router'
import { VFC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Tagging } from 'features/get-tags'
import { TitleContent } from 'shared/ui/title-content'
import { useCreatedFeed } from '../createFeed/model/__generated__/CreatedFeed'
import { Wrapper } from './styled'
import { Inputs, Props } from './types'

export const CreateFeed: VFC<Props> = () => {
    const navigate = useNavigate()
    const [createFeed] = useCreatedFeed()

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        createFeed({
            variables: { name: data.name, tagUids: data.tagUids },
        })
        navigate(`/feeds`)
    }

    return (
        <TitleContent title='Создание ленты'>
            <Wrapper>
                <Tagging
                    buttonText='Создать'
                    inputValue=''
                    onSubmit={onSubmit}
                />
            </Wrapper>
        </TitleContent>
    )
}
