import { MouseEvent, useState, VFC } from 'react'
import { ComponentsList, ComponentsQuery } from 'entities/componets'
import { PostsList, PostsQuery } from 'entities/posts'
import { Props } from './types'

export const PostComponentList: VFC<Props> = ({ postUids, onClick }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [post, setPost] = useState<null | PostsQuery['posts']['items'][0]>(
        null,
    )

    const postHandelClick =
        (post: PostsQuery['posts']['items'][0]) =>
        (event: MouseEvent<HTMLElement>) => {
            setPost(post)
            setAnchorEl(event.currentTarget)
        }
    const componentHandelClick = (
        component: ComponentsQuery['components'][0],
    ) => {
        setAnchorEl(null)
        if (post) onClick(component, post)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setPost(null)
    }

    return (
        <>
            <PostsList
                ariaControls='components-list'
                postUids={postUids}
                onClick={postHandelClick}
            />
            <ComponentsList
                anchorEl={anchorEl}
                handleClose={handleClose}
                labelledby={post?.uid}
                onClick={componentHandelClick}
            />
        </>
    )
}
