import Typography from '@mui/material/Typography'

export const AvatarCaption = () => (
    <Typography
        marginTop='16px'
        sx={{
            textAlign: 'center',
            display: 'block',
            lineHeight: '1.5',
            color: 'rgb(99, 115, 129)',
        }}
        variant='caption'
    >
        Разрешены *.jpeg, *.jpg, *.png, *.gif с максимальным размером не более 2
        MB
    </Typography>
)
