import { Box, Container, Divider, Paper } from '@mui/material'
import { VFC } from 'react'
import { usePostQuery } from 'features/post/model/__generated__/PostQuery'
import { env } from 'shared/config'
import { MuiMarkdown } from 'shared/ui'
import { Comments } from './comments'
import { Interactions, PostDescription, PostHeader, Tags } from './ui'

type Props = {
    postUid: string
}

export const Post: VFC<Props> = ({ postUid }) => {
    const { loading, data, error } = usePostQuery({
        variables: {
            uid: postUid,
        },
    })

    if (error) {
        return <div>{error}</div>
    }

    if (loading) {
        return <div>loading...</div>
    }

    if (!data) {
        return <div>no data</div>
    }

    return (
        <Container>
            <Paper>
                <PostHeader
                    data={data.post}
                    external={
                        env.app.postLink &&
                        env.app.postLink.replace(':uid', postUid)
                    }
                />
                <Box
                    sx={{
                        padding: '40px',
                        boxSizing: 'border-box',
                    }}
                >
                    {data.post.description && (
                        <PostDescription>
                            {data.post.description}
                        </PostDescription>
                    )}
                    <MuiMarkdown>{data.post.content}</MuiMarkdown>
                    <div style={{ margin: '40px 0' }}>
                        <Divider />
                        <Box sx={{ padding: '24px 0' }}>
                            <Tags data={data.post} />
                            <Interactions data={data.post} />
                        </Box>
                        <Divider />
                    </div>
                    <Comments postUid={postUid} />
                </Box>
            </Paper>
        </Container>
    )
}
