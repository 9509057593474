// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TagsVariables = Types.Exact<{ [key: string]: never; }>;


export type Tags = { tags: Array<{ uid: any, name: string }> };


export const TagsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useTags__
 *
 * To run a query within a React component, call `useTags` and pass it any options that fit your needs.
 * When your component renders, `useTags` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTags({
 *   variables: {
 *   },
 * });
 */
export function useTags(baseOptions?: Apollo.QueryHookOptions<Tags, TagsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Tags, TagsVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Tags, TagsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Tags, TagsVariables>(TagsDocument, options);
        }
export type TagsHookResult = ReturnType<typeof useTags>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<Tags, TagsVariables>;