import { Avatar, Box, Typography } from '@mui/material'
import { Link } from '@reach/router'
import { VFC } from 'react'
import { useCurrentUser } from 'entities/viewer/model/__generated__/CurrentUser'
import { NavigationProfileProps } from './types'

export const NavigationProfile: VFC<NavigationProfileProps> = () => {
    const { data, loading, error } = useCurrentUser()
    if (loading || !data || error) {
        return null
    }

    return (
        <Box
            component={Link}
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px 20px',
                textDecoration: 'none',
                color: 'inherit',
                background: 'rgba(145, 158, 171, 0.12)',
                margin: '16px',
                borderRadius: '12px',
            }}
            to='/profile'
        >
            <Avatar
                src={data.currentUser.profile?.avatarUrl ?? ''}
                sx={{ width: '40px', height: '40px' }}
            />
            <Box sx={{ margin: '0 12px' }}>
                <Typography sx={{ fontWeight: '600' }} variant='subtitle2'>
                    {data.currentUser.profile?.publicName}
                </Typography>
                <Typography sx={{ color: 'rgb(99, 115, 129)' }} variant='body2'>
                    @{data.currentUser.login}
                </Typography>
            </Box>
        </Box>
    )
}

export type { NavigationProfileProps }
