export const env = {
    isDevelopment:
        !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    app: {
        baseUrl:
            window._env_?.REACT_APP_API_GATEWAY ||
            process.env.REACT_APP_API_GATEWAY,
        sharedLink:
            window._env_?.REACT_APP_JOURNAL_SHARED_LINK ||
            process.env.REACT_APP_JOURNAL_SHARED_LINK,
        postLink:
            window._env_?.REACT_APP_JOURNAL_POST_LINK ||
            process.env.REACT_APP_JOURNAL_POST_LINK,
    },
} as const
