import { Autocomplete, FormHelperText, TextField } from '@mui/material'
import { VFC } from 'react'
import { Props } from './types'

export const Multiple: VFC<Props> = ({
    options,
    setValue,
    defaultValue,
    tagsUid,
    formState,
    ...props
}) => {
    const formStateError = formState.errors.tagUids

    return (
        <>
            {options && (
                <Autocomplete
                    {...props}
                    multiple
                    defaultValue={defaultValue}
                    getOptionLabel={(option: any) => option.name}
                    options={options}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Выберите теги'
                            placeholder=''
                            variant='outlined'
                        />
                    )}
                    onChange={(e, options) =>
                        setValue('tagUids', tagsUid(options))
                    }
                />
            )}
            <FormHelperText sx={{ color: 'red' }}>
                {formStateError?.message || ' '}
            </FormHelperText>
        </>
    )
}
