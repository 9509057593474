export const hashCode = (title: string) =>
    title.split('').reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0)
        return a & a
    }, 0)
export const decimalColorToHTMLcolor = (number: number) => {
    let intnumber = number - 0
    let red, green, blue
    let template = '#000000'
    red = (intnumber & 0x0000ff) << 16
    green = intnumber & 0x00ff00
    blue = (intnumber & 0xff0000) >>> 16
    intnumber = red | green | blue
    let HTMLcolor = intnumber.toString(16)
    HTMLcolor = template.substring(0, 7 - HTMLcolor.length) + HTMLcolor
    return HTMLcolor
}
