import { LinearProgress } from '@mui/material'
import { VFC } from 'react'
import { FeedForm } from 'entities/componets/ui/feedsForm'
import { useTags } from './model/__generated__/Tags'
import { Props } from './types'

export const Tagging: VFC<Props> = ({
    defaultValue,
    inputValue,
    buttonText,
    onSubmit,
}) => {
    const { data: dataTags, loading } = useTags()

    return (
        <>
            {loading && <LinearProgress />}
            {dataTags && (
                <FeedForm
                    buttonText={buttonText}
                    defaultValue={defaultValue}
                    inputValue={inputValue}
                    options={dataTags.tags}
                    onSubmit={onSubmit}
                />
            )}
        </>
    )
}
