// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentFrontPageVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentFrontPage = { currentFrontPage: { uid: any, publicationDate?: any | null, updatedAt: any, createdAt: any, title: string } };


export const CurrentFrontPageDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CurrentFrontPage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentFrontPage"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"publicationDate"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useCurrentFrontPage__
 *
 * To run a query within a React component, call `useCurrentFrontPage` and pass it any options that fit your needs.
 * When your component renders, `useCurrentFrontPage` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentFrontPage({
 *   variables: {
 *   },
 * });
 */
export function useCurrentFrontPage(baseOptions?: Apollo.QueryHookOptions<CurrentFrontPage, CurrentFrontPageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentFrontPage, CurrentFrontPageVariables>(CurrentFrontPageDocument, options);
      }
export function useCurrentFrontPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentFrontPage, CurrentFrontPageVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentFrontPage, CurrentFrontPageVariables>(CurrentFrontPageDocument, options);
        }
export type CurrentFrontPageHookResult = ReturnType<typeof useCurrentFrontPage>;
export type CurrentFrontPageLazyQueryHookResult = ReturnType<typeof useCurrentFrontPageLazyQuery>;
export type CurrentFrontPageQueryResult = Apollo.QueryResult<CurrentFrontPage, CurrentFrontPageVariables>;