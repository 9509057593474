import { yupResolver } from '@hookform/resolvers/yup'
import { Button, TextField } from '@mui/material'
import { VFC } from 'react'
import { useForm } from 'react-hook-form'
import { Multiple } from 'shared/ui/multiple-select'
import { validationSchema } from './lib/validation-schema'
import { StyledForm } from './styled'
import { Inputs, Props } from './types'

export const FeedForm: VFC<Props> = ({
    onSubmit,
    options,
    inputValue,
    buttonText,
    defaultValue,
}) => {
    const tagsUid = (val: any) => {
        let tags: string[] | undefined = []
        val[0]
            ? val.map((tag: string) => {
                  tags?.push(Object.values(tag)[1])
              })
            : (tags = undefined)
        return tags
    }

    const { register, handleSubmit, setValue, formState } = useForm<Inputs>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            tagUids: defaultValue ? tagsUid(defaultValue) : undefined,
        },
    })

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <TextField
                {...register('name')}
                fullWidth
                defaultValue={inputValue}
                error={!!formState.errors.name}
                helperText={formState.errors.name?.message || ' '}
                label='Название ленты'
                variant='outlined'
            />
            <Multiple
                {...register('tagUids')}
                defaultValue={defaultValue}
                formState={formState}
                inputText='Выберите теги'
                options={options}
                setValue={setValue}
                tagsUid={tagsUid}
            />

            <Button
                sx={{ width: '300px', height: '40px' }}
                type='submit'
                variant='contained'
            >
                {buttonText}
            </Button>
        </StyledForm>
    )
}
