import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { RouteComponentProps, Link as RouterLink } from '@reach/router'
import { FC, useState } from 'react'
import { useFrontPagesAll } from 'pages/front-pages-all/models/__generated__/FrontPagesAll'

const columns = [
    {
        id: 'uid',
        label: 'uid',
        minWidth: 170,
        format: (value: any) => (
            <Link component={RouterLink} to={`/front-pages/${value}`}>
                {value}
            </Link>
        ),
    },
    { id: 'title', label: 'Заголовок', minWidth: 170 },
    {
        id: 'createdAt',
        label: 'Создан',
        minWidth: 170,
        format: (value: number) => new Date(value).toLocaleDateString(),
    },
    {
        id: 'updatedAt',
        label: 'Обновлен',
        minWidth: 170,
        format: (value?: number) =>
            value ? new Date(value).toLocaleDateString() : null,
    },
    {
        id: 'publicationDate',
        label: 'Начало публикации',
        minWidth: 170,
        format: (value?: number) =>
            value ? new Date(value).toLocaleDateString() : null,
    },
    {
        id: 'endDate',
        label: 'Окончания публикации',
        minWidth: 170,
        format: (value?: number) =>
            value ? new Date(value).toLocaleDateString() : null,
    },
]

export const FrontPagesAll: FC<RouteComponentProps> = () => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const { data } = useFrontPagesAll({
        variables: { page: page + 1, perPage: rowsPerPage },
        fetchPolicy: 'no-cache',
    })

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', height: '100vh' }}>
            <TableContainer sx={{ maxHeight: '90%' }}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data &&
                            data.frontPages.items.map((row) => (
                                <TableRow key={row.uid}>
                                    {columns.map((column) => {
                                        const id = column.id as
                                            | 'uid'
                                            | 'createdAt'
                                            | 'updatedAt'
                                            | 'title'
                                        return (
                                            <TableCell>
                                                {column.format
                                                    ? column.format(row[id])
                                                    : row[id]}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component='div'
                count={data ? data.frontPages.count : 0}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    )
}
