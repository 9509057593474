// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFeedVariables = Types.Exact<{
  name: Types.Scalars['String'];
  tagUids: Array<Types.Scalars['UID']> | Types.Scalars['UID'];
  uid: Types.Scalars['UID'];
}>;


export type UpdateFeed = { updateFeed: { name: string } };


export const UpdateFeedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateFeed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tagUids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UID"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateFeed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"tagUids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tagUids"}}}]}},{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateFeedMutationFn = Apollo.MutationFunction<UpdateFeed, UpdateFeedVariables>;

/**
 * __useUpdateFeed__
 *
 * To run a mutation, you first call `useUpdateFeed` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeed` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeed, { data, loading, error }] = useUpdateFeed({
 *   variables: {
 *      name: // value for 'name'
 *      tagUids: // value for 'tagUids'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUpdateFeed(baseOptions?: Apollo.MutationHookOptions<UpdateFeed, UpdateFeedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeed, UpdateFeedVariables>(UpdateFeedDocument, options);
      }
export type UpdateFeedHookResult = ReturnType<typeof useUpdateFeed>;
export type UpdateFeedMutationResult = Apollo.MutationResult<UpdateFeed>;
export type UpdateFeedMutationOptions = Apollo.BaseMutationOptions<UpdateFeed, UpdateFeedVariables>;