import styled from '@emotion/styled'
import { Drawer } from '@mui/material'
import { NAVBAR_WIDTH } from 'shared/ui'

export const StyledDrawer = styled(Drawer)`
    width: ${NAVBAR_WIDTH}px;
    flex-shrink: 0;
    white-space: nowrap;
    box-sizing: border-box;

    & .MuiDrawer-paper {
        width: ${NAVBAR_WIDTH}px;
        overflow-x: hidden;
    }
`
