import compose from 'compose-function'

import { withApollo } from './withApollo'
import { withGlobalStyles } from './withGlobalStyles'
import { withTheme } from './withTheme'
import { withToasts } from './withToasts'

export const withProviders = compose(
    withApollo,
    withToasts,
    withGlobalStyles,
    withTheme,
)
