// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFrontPageMutationVariables = Types.Exact<{
  data: Types.CreateFrontPageInput;
}>;


export type CreateFrontPageMutation = { createFrontPage: { uid: any, title: string } };


export const CreateFrontPageMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateFrontPageMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateFrontPageInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFrontPage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]}}]} as unknown as DocumentNode;
export type CreateFrontPageMutationMutationFn = Apollo.MutationFunction<CreateFrontPageMutation, CreateFrontPageMutationVariables>;

/**
 * __useCreateFrontPageMutation__
 *
 * To run a mutation, you first call `useCreateFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFrontPageMutation, { data, loading, error }] = useCreateFrontPageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateFrontPageMutation(baseOptions?: Apollo.MutationHookOptions<CreateFrontPageMutation, CreateFrontPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFrontPageMutation, CreateFrontPageMutationVariables>(CreateFrontPageMutationDocument, options);
      }
export type CreateFrontPageMutationHookResult = ReturnType<typeof useCreateFrontPageMutation>;
export type CreateFrontPageMutationMutationResult = Apollo.MutationResult<CreateFrontPageMutation>;
export type CreateFrontPageMutationMutationOptions = Apollo.BaseMutationOptions<CreateFrontPageMutation, CreateFrontPageMutationVariables>;