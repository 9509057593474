import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { VFC } from 'react'

const Box = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
`

export const Loader: VFC = () => (
    <Box>
        <CircularProgress />
    </Box>
)
