// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangePostsOnFrontPageMutationVariables = Types.Exact<{
  uid: Types.Scalars['UID'];
  data: Array<Types.AddPostToFrontPageInput> | Types.AddPostToFrontPageInput;
}>;


export type ChangePostsOnFrontPageMutation = { changePostsOnFrontPage: Array<{ uid: any }> };


export const ChangePostsOnFrontPageMutationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangePostsOnFrontPageMutation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddPostToFrontPageInput"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changePostsOnFrontPage"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"frontPageUid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]} as unknown as DocumentNode;
export type ChangePostsOnFrontPageMutationMutationFn = Apollo.MutationFunction<ChangePostsOnFrontPageMutation, ChangePostsOnFrontPageMutationVariables>;

/**
 * __useChangePostsOnFrontPageMutation__
 *
 * To run a mutation, you first call `useChangePostsOnFrontPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePostsOnFrontPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePostsOnFrontPageMutation, { data, loading, error }] = useChangePostsOnFrontPageMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePostsOnFrontPageMutation(baseOptions?: Apollo.MutationHookOptions<ChangePostsOnFrontPageMutation, ChangePostsOnFrontPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePostsOnFrontPageMutation, ChangePostsOnFrontPageMutationVariables>(ChangePostsOnFrontPageMutationDocument, options);
      }
export type ChangePostsOnFrontPageMutationHookResult = ReturnType<typeof useChangePostsOnFrontPageMutation>;
export type ChangePostsOnFrontPageMutationMutationResult = Apollo.MutationResult<ChangePostsOnFrontPageMutation>;
export type ChangePostsOnFrontPageMutationMutationOptions = Apollo.BaseMutationOptions<ChangePostsOnFrontPageMutation, ChangePostsOnFrontPageMutationVariables>;