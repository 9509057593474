import { RouteComponentProps } from '@reach/router'
import { VFC } from 'react'
import { Post as PostFeature } from 'features/post'
import { Wrapper } from './styled'

type Props = RouteComponentProps<{
    postUid: string
}>

export const Post: VFC<Props> = ({ postUid }) => (
    <Wrapper>
        <PostFeature postUid={postUid!} />
    </Wrapper>
)
