import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { useEffect, useState, VFC } from 'react'
import { AddPostToFrontPageInput } from '__generated__/types'
import { ComponentsQuery } from 'entities/componets'
import { PostsQuery } from 'entities/posts'
// TODO: front-page-manager model will be move to entities
// eslint-disable-next-line boundaries/element-types
import { CurrentFrontPageDocument } from 'features/front-page-manager/model/__generated__/CurrentFrontPage'
import { env } from 'shared/config'
import { GridAria, Loader, PositionedMenu, TitleContent } from 'shared/ui'
import { ResponsiveGrid } from 'shared/ui/griad/responsive-grid'
import { LayoutType } from 'shared/ui/griad/types'
import {
    useChangePostsOnFrontPageMutation,
    useFrontPageQuery,
    usePublishFrontPageMutation,
    useShareFrontPageMutation,
} from '../../model'
import { PostComponentList } from '../post-componet-list'
import { ContentType, Props } from './types'

const ConstructorGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    gap: 8px;
    grid-template:
        'header header' auto
        'constructor posts' 1fr /
        1fr 500px;
`

export const Constructor: VFC<Props> = ({ uid }) => {
    const { loading, data, error } = useFrontPageQuery({
        fetchPolicy: 'no-cache',
        variables: { uid },
    })
    const [changePostsOnFrontPage] = useChangePostsOnFrontPageMutation()
    const [publishFrontPage] = usePublishFrontPageMutation({
        refetchQueries: [{ query: CurrentFrontPageDocument }],
    })
    const [shareFrontPage] = useShareFrontPageMutation()
    const [layout, setLayout] = useState<LayoutType[]>([])
    const [content, setContent] = useState<ContentType>({})

    useEffect(() => {
        if (data) {
            const content: ContentType = {}
            const layout: LayoutType[] = data.frontPage.content.map(
                ({ post, component, position }) => {
                    content[post.uid] = { post, component }

                    return {
                        i: post.uid,
                        x: position.x,
                        y: position.y,
                        h: component.configuration.h,
                        w: component.configuration.w,
                    }
                },
            )

            setContent(content)
            setLayout(layout)
        }
    }, [data])

    if (loading) return <Loader />
    if (error) return <div>Ошибка загрузки</div>

    const componentOnClick = (
        component: ComponentsQuery['components'][0],
        post: PostsQuery['posts']['items'][0],
    ) => {
        setContent((prevContent) => ({
            ...prevContent,
            [post.uid]: { post, component },
        }))
        setLayout((prevLayout) => [
            ...prevLayout,
            {
                h: component.configuration.h,
                i: post.uid,
                w: component.configuration.w,
                x: 0,
                y: 0,
            },
        ])
    }

    const onSave = () => {
        const data: AddPostToFrontPageInput[] = layout.map(({ i, x, y }) => ({
            postUid: content[i].post.uid,
            componentUid: content[i].component.uid,
            position: { x, y },
        }))

        changePostsOnFrontPage({ variables: { uid, data } })
    }

    const onShare = () => {
        shareFrontPage({ variables: { uid } }).then(() => {
            if (env.app.sharedLink) {
                window.open(env.app.sharedLink.replace(':uid', uid))
            }
        })
    }

    const onPublish = () => {
        publishFrontPage({ variables: { uid } })
    }

    const onDelete = (uid: string) => {
        setContent(({ [uid]: _, ...prevContent }) => prevContent)
        setLayout((prevLayout) => prevLayout.filter(({ i }) => i !== uid))
    }

    return (
        <ConstructorGrid>
            <GridAria area='header'>
                <TitleContent
                    second={
                        <>
                            <PositionedMenu
                                text='Предпросмотр'
                                onClick={onShare}
                            />
                            <Button onClick={onPublish}>Опубликовать</Button>
                            <Button onClick={onSave}>Сохранить</Button>
                        </>
                    }
                    title={`Публикация "${data?.frontPage.title}"`}
                />
            </GridAria>
            <GridAria area='constructor'>
                <ResponsiveGrid
                    content={content}
                    layout={layout}
                    onChange={setLayout}
                    onDelete={onDelete}
                />
            </GridAria>
            <GridAria area='posts'>
                <PostComponentList
                    postUids={Object.keys(content)}
                    onClick={componentOnClick}
                />
            </GridAria>
        </ConstructorGrid>
    )
}
