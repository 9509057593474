import styled from '@emotion/styled'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ReplyIcon from '@mui/icons-material/Reply'
import {
    Avatar,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { FC, VFC } from 'react'
import { GetCommentsByPostUid } from 'features/post/model/__generated__/GetCommentsByPostUid'

type CommentType = GetCommentsByPostUid['comments']['items'][0]

const Line = styled.span`
    display: block;
`

export type CommentProps = {
    data: CommentType
    onDelete?: (comment: CommentType) => void
    onReply?: (comment: CommentType) => void
}

const Reply: FC = ({ children }) => (
    <Typography
        component='blockquote'
        sx={{
            padding: '16px',
            margin: '16px',
            background: 'rgba(0, 0, 0, .05)',
        }}
        variant='body2'
    >
        {children}
    </Typography>
)

export const Comment: VFC<CommentProps> = ({ data, onDelete, onReply }) => {
    const authorName =
        data.author.publicName || data.author.fullName || 'Аноним'

    const handleDelete = () => {
        onDelete && onDelete(data)
    }

    const handleReply = () => {
        onReply && onReply(data)
    }

    return (
        <>
            <ListItem alignItems='flex-start'>
                <ListItemAvatar>
                    <Avatar
                        alt={authorName}
                        src={data.author.avatarUrl || ''}
                    />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            sx={{ fontWeight: 600 }}
                            variant='subtitle2'
                        >
                            {authorName}
                        </Typography>
                    }
                    secondary={
                        <>
                            <Typography variant='caption'>
                                {format(
                                    parseISO(data.createdAt),
                                    'PPP в HH:mm',
                                    {
                                        locale: ru,
                                    },
                                )}
                            </Typography>
                            {data.replyingToComment && (
                                <Reply>
                                    {data.replyingToComment.content
                                        .split('\n')
                                        .map((line, index) => (
                                            <Line key={index}>{line}</Line>
                                        ))}
                                </Reply>
                            )}
                            <Typography component='blockquote' variant='body2'>
                                {data.content.split('\n').map((line, index) => (
                                    <Line key={index}>{line}</Line>
                                ))}
                            </Typography>
                        </>
                    }
                />
                {onReply && (
                    <IconButton
                        aria-label='reply comment'
                        onClick={handleReply}
                    >
                        <ReplyIcon />
                    </IconButton>
                )}
                {onDelete && (
                    <IconButton
                        aria-label='delete comment'
                        color='error'
                        onClick={handleDelete}
                    >
                        <DeleteOutlineIcon />
                    </IconButton>
                )}
            </ListItem>
            <Divider component='li' variant='inset' />
        </>
    )
}
