import styled from '@emotion/styled'
import React, { FC, useState } from 'react'
import { AvatarImg } from './avatar-img'
import { Placeholder } from './placeholder'

const UploadLabel = styled.label`
    z-index: 0;
    outline: none;
    display: flex;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const Wrapper = styled.div`
    width: 126px;
    height: 126px;
    margin: auto;
    border-radius: 50%;
    padding: 8px;
    border: 1px dashed rgba(145, 158, 171, 0.32);
`

type Props = {
    disable?: boolean
    src?: string
    register?: any
    onChange?: (data: FileList) => void
}

export const AvatarUpload: FC<Props> = ({ src, onChange }) => {
    const [img, setImg] = useState<string>(src ?? '')

    const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        if (event.currentTarget && event.currentTarget.files) {
            // eslint-disable-next-line prefer-destructuring
            const newImg = event.currentTarget.files[0]
            newImg && setImg(URL.createObjectURL(newImg))
            onChange && onChange(event.currentTarget.files)
        }
    }

    return (
        <Wrapper>
            <UploadLabel role='button' tabIndex={0}>
                <input
                    hidden
                    accept='image/*'
                    autoComplete='off'
                    style={{ display: 'none' }}
                    tabIndex={-1}
                    type='file'
                    onChange={handleChange}
                />
                <AvatarImg src={img} />
                <Placeholder />
            </UploadLabel>
        </Wrapper>
    )
}
