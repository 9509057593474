import { Chip, Typography } from '@mui/material'
import { useNavigate } from '@reach/router'
import { FC } from 'react'
import {
    decimalColorToHTMLcolor,
    hashCode,
} from 'shared/ui/utils/string-to-color'
import { ListItemButton, ListItemText, StyledButton } from './styled'
import { Props } from './types'

export const FeedsItem: FC<Props> = ({ uid, title, tags }) => {
    const navigate = useNavigate()
    return (
        <ListItemButton>
            <ListItemText>
                <Typography>{title}</Typography>
                <div>
                    {tags?.map((item) => (
                        <Chip
                            key={item.uid}
                            label={item.name}
                            sx={{
                                m: '2px',
                                bgcolor: decimalColorToHTMLcolor(
                                    hashCode(item.name),
                                ),
                            }}
                        />
                    ))}
                </div>
            </ListItemText>

            <StyledButton
                size='small'
                type='submit'
                variant='contained'
                onClick={() => navigate(`/feeds/${uid}/edit`)}
            >
                Изменить
            </StyledButton>
        </ListItemButton>
    )
}
