import { RouteComponentProps, Router, useNavigate } from '@reach/router'
import { Suspense, VFC } from 'react'
import { useCurrentUser } from 'entities/viewer'
import { SignInPage } from 'pages/sign-in'
import { AuthorizedLayout, Loader } from 'shared/ui'
import { Navbar } from 'widgets/navbar'
import { Drafts } from './drafts'
import { GetFeedsComponent, UpdateFeed } from './feeds'
import { CreateFeed } from './feeds/createFeed'
import { FrontPage } from './front-page'
import { FrontPages } from './front-pages'
import { FrontPagesAll } from './front-pages-all'
import { Post } from './post'
import { Posts } from './posts'
import { Profile } from './profile'
import { Users } from './users'

// const DraftsRouting = lazy(() =>
//     import('pages/drafts').then((m) => ({
//         default: m.Drafts,
//     })),
// )

export const Backoffice: VFC<RouteComponentProps> = () => {
    const { data: profile, loading, error } = useCurrentUser()
    const navigate = useNavigate()

    if (loading) {
        return <Loader />
    }

    // Если пользователь не авторизован отправляем на страницу авторизации
    if (!profile || error) {
        navigate('/sign-in')
        return <Loader />
    }
    // TODO FrontPagesAll will be modify to Search
    return (
        <AuthorizedLayout navbar={<Navbar />}>
            <Suspense fallback={<Loader />}>
                <Router>
                    <Drafts path='drafts/*' />
                    <FrontPages path='front-pages' />
                    <FrontPagesAll path='front-pages/all/' />
                    <FrontPage path='front-pages/:frontPageUid' />
                    <CreateFeed path='feeds/create' />
                    <GetFeedsComponent path='feeds' />
                    <UpdateFeed path='feeds/:feedUid/edit' />
                    <Posts path='posts' />
                    <Post path='posts/:postUid' />
                    <Users path='users' />
                    <Profile path='profile' />
                </Router>
            </Suspense>
        </AuthorizedLayout>
    )
}

export const Routing = () => (
    <Router>
        <Backoffice default path='/*' />

        <SignInPage path='/sign-in' />
    </Router>
)
