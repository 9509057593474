import { VFC } from 'react'
import ReactGridLayout from 'react-grid-layout'
import AutoSizer from 'react-virtualized-auto-sizer'
// eslint-disable-next-line
import { ComponentSkeleton } from 'entities/componets/ui/component-skeleton'
import { Props } from './types'

export const ResponsiveGrid: VFC<Props> = ({
    layout,
    content,
    onChange,
    onDelete,
}) => (
    <div
        style={{
            boxSizing: 'border-box',
            margin: '0 auto',
            border: '1px solid ',
            width: '100%',
            height: '100%',
        }}
    >
        <AutoSizer>
            {({ width }) => (
                <ReactGridLayout
                    className='layout'
                    cols={4}
                    layout={layout}
                    rowHeight={width / 4}
                    width={width}
                    onLayoutChange={onChange}
                >
                    {layout.map(({ i }) => (
                        <div
                            key={i}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                border: '1px solid',
                            }}
                        >
                            <ComponentSkeleton
                                description={content[i].post.description || ''}
                                previewUrl={content[i].post.previewUrl || ''}
                                title={content[i].post.title}
                                type={content[i].component.configuration.type}
                                onClick={() => onDelete(i)}
                            />
                        </div>
                    ))}
                </ReactGridLayout>
            )}
        </AutoSizer>
    </div>
)
