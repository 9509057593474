import { Box, Paper, Table, TableBody, TableContainer } from '@mui/material'
import React from 'react'
import {
    GetUsers,
    useGetUsers,
} from 'features/users-table/model/__generated__/GetUsers'
import { tableHeadCells } from './config'
import { UsersTableHead, UsersTableRow, UsersTableToolbar } from './ui'

export const UsersTable = () => {
    const { data } = useGetUsers()
    const [selected, setSelected] = React.useState<readonly string[]>([])

    if (!data) {
        return null
    }

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = data.users.map((n) => n.uid)
            setSelected(newSelecteds)
            return
        }
        setSelected([])
    }

    const handleClick = (_: any, user: GetUsers['users'][0]) => {
        const selectedIndex = selected.indexOf(user.uid)
        let newSelected: readonly string[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, user.uid)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const isSelected = (name: string) => selected.indexOf(name) !== -1

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <UsersTableToolbar selectedCount={selected.length} />
                <TableContainer>
                    <Table aria-labelledby='tableTitle' sx={{ minWidth: 750 }}>
                        <UsersTableHead
                            data={tableHeadCells}
                            itemsCount={data.users.length}
                            selectedCount={selected.length}
                            onSelectAll={handleSelectAllClick}
                        />
                        <TableBody>
                            {data.users.map((user) => (
                                <UsersTableRow
                                    data={user}
                                    selected={isSelected(user.uid)}
                                    onClick={handleClick}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}
