import styled from '@emotion/styled'
import GitHubIcon from '@mui/icons-material/GitHub'
import ShareIcon from '@mui/icons-material/Share'
import {
    Avatar,
    Box,
    Button,
    Fab,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { VFC } from 'react'
import { PostQuery } from 'features/post/model/__generated__/PostQuery'
import { PostTitle } from './post-title'

const ImageWrapper = styled.div`
    inset: 0px;
    line-height: 0;
    position: absolute;
    background-size: cover !important;
    filter: blur(0);
    transition: filter 0.3s;
`

const ImageOverlay = styled.div`
    inset: 0px;
    z-index: 9;
    position: absolute;
    background-color: rgba(22, 28, 36, 0.72);
`
const ActionBlock = styled.div`
    bottom: 0px;
    z-index: 10;
    width: 100%;
    display: flex;
    position: absolute;
    align-items: flex-end;
    padding-left: 24px;
    padding-right: 16px;
    padding-bottom: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 80px;
    box-sizing: border-box;
`

type Props = {
    data: PostQuery['post']
    external?: string
}

const extractDataFromGithubAuthor = (
    author: PostQuery['post']['githubAuthor'],
) => {
    if (!author) {
        return {
            name: 'Аноним',
            avatarUrl: '',
        }
    }

    if (author.user) {
        const name = author.user.profile?.publicName ?? author.user.login
        const avatarUrl =
            author.user.profile?.avatarUrl ?? (author.avatarUrl || '')
        return {
            name,
            avatarUrl,
        }
    }

    return {
        name: author.name || 'Аноним',
        avatarUrl: author.avatarUrl || '',
    }
}

export const PostHeader: VFC<Props> = ({ data, external }) => {
    const author = extractDataFromGithubAuthor(data.githubAuthor)
    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <PostTitle>{data.title}</PostTitle>
            <ActionBlock>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        src={author.avatarUrl}
                        sx={{ width: '48px', height: '48px' }}
                    />
                    <Box sx={{ marginLeft: '16px' }}>
                        <Typography
                            sx={{
                                color: 'rgb(255, 255, 255)',
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            variant='subtitle2'
                        >
                            {author.name}
                            {data.githubAuthor && (
                                <IconButton
                                    component='a'
                                    href={data.githubAuthor?.usernameUrl}
                                    rel='noopener noreferrer'
                                    size='small'
                                    sx={{ marginLeft: '4px' }}
                                    target='_blank'
                                >
                                    <GitHubIcon
                                        sx={{ fill: 'rgb(255, 255, 255)' }}
                                    />
                                </IconButton>
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'rgb(145, 158, 171)',
                                fontWeight: 400,
                            }}
                            variant='body2'
                        >
                            {format(parseISO(data.createdAt), 'PPP', {
                                locale: ru,
                            })}
                        </Typography>
                    </Box>
                </Box>
                <Stack
                    direction='row'
                    spacing={2}
                    sx={{ alignItems: 'center' }}
                >
                    {external && (
                        <Button
                            component='a'
                            href={external}
                            rel='noopener noreferrer'
                            target='_blank'
                            variant='contained'
                        >
                            Открыть в журнале
                        </Button>
                    )}
                    <Fab
                        color='primary'
                        sx={{
                            position: 'relative',
                            zIndex: 1000,
                            width: '48px',
                            height: '48px',
                        }}
                    >
                        <ShareIcon />
                    </Fab>
                </Stack>
            </ActionBlock>
            <ImageOverlay />
            <Box
                sx={{
                    width: '100%',
                    lineHeight: 0,
                    display: 'block',
                    overflow: 'hidden',
                    position: 'relative',
                    paddingTop: 'calc(56.25%)',
                }}
            >
                <ImageWrapper>
                    <Box
                        component='img'
                        src={data.previewUrl || ''}
                        sx={{
                            display: 'block',
                            maxWidth: '100%',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            opacity: 1,
                            transition: 'opacity 0.3s',
                        }}
                    />
                </ImageWrapper>
            </Box>
        </Box>
    )
}
