import { SnackbarProvider } from 'notistack'
import { ReactNode } from 'react'

export const withToasts = (app: () => ReactNode) => {
    const ToastsWrapper = () => (
        <SnackbarProvider
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            maxSnack={3}
        >
            {app()}
        </SnackbarProvider>
    )

    ToastsWrapper.displayName = 'ToastsWrapper'

    return ToastsWrapper
}
