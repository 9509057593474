import { Button, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { Props } from './types'

// TODO: переделать
export const TitleContent: FC<Props> = ({
    title,
    onCreate,
    second,
    children,
}) => (
    <Grid
        container
        alignItems='center'
        columns={2}
        justifyContent='space-between'
    >
        <Grid item xs='auto'>
            <Typography variant='h4'>{title}</Typography>
        </Grid>
        <Grid item xs='auto'>
            {second}
            {onCreate && <Button onClick={onCreate}>Создать</Button>}
        </Grid>

        <Grid item xs={2}>
            {children}
        </Grid>
    </Grid>
)
