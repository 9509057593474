import styled from '@emotion/styled'
import { FC } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { Blockquote as blockquote } from './blockquote'
import { Code as code } from './code'
import { Divider as hr } from './divider'
import { Img as img } from './img'
import { Link as a } from './link'
import { ListItem as li } from './list-item'
import {
    H1 as h1,
    H2 as h2,
    H3 as h3,
    H4 as h4,
    H5 as h5,
    H6 as h6,
    Paragraph as p,
} from './typography'

const Styl = styled(ReactMarkdown)`
    font-family: Roboto, Helvetica, Arial, sans-serif;
`

const components = {
    code,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    img,
    a,
    li,
    hr,
    blockquote,
}

export const MuiMarkdown: FC<Options> = ({ children }) => (
    <Styl
        children={children}
        components={components}
        remarkPlugins={[remarkGfm]}
    />
)
