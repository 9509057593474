import { FC } from 'react'
import { CenterCard } from '../center-card'
import { ColumnCard } from '../column-card'
import { Props } from './types'

export const ComponentGrid: FC<Props> = ({ type, children }) =>
    type === 'center' ? (
        <CenterCard>{children}</CenterCard>
    ) : (
        <ColumnCard>{children}</ColumnCard>
    )
