// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCommentVariables = Types.Exact<{
  data: Types.CreateCommentInput;
}>;


export type CreateComment = { createComment: { uid: any, createdAt: any, postUid: any, content: string, replyingToComment?: { uid: any, content: string } | null, author: { publicName?: string | null, avatarUrl?: string | null, fullName?: string | null } } };


export const CreateCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreateComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CreateCommentInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"replyingToComment"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"content"}}]}},{"kind":"Field","name":{"kind":"Name","value":"postUid"}},{"kind":"Field","name":{"kind":"Name","value":"content"}},{"kind":"Field","name":{"kind":"Name","value":"author"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"publicName"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}}]}}]}}]}}]} as unknown as DocumentNode;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateComment, CreateCommentVariables>;

/**
 * __useCreateComment__
 *
 * To run a mutation, you first call `useCreateComment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComment, { data, loading, error }] = useCreateComment({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateComment(baseOptions?: Apollo.MutationHookOptions<CreateComment, CreateCommentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateComment, CreateCommentVariables>(CreateCommentDocument, options);
      }
export type CreateCommentHookResult = ReturnType<typeof useCreateComment>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateComment>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateComment, CreateCommentVariables>;