import { css, Global } from '@emotion/react'
import { ReactNode } from 'react'

const globalStyles = css`
    body {
        margin: 0;
        padding: 0;
    }

    //div[tabindex='-1'] {
    //    height: 100%;
    //    width: 100%;
    //    display: unset;
    //}

    div[id='root'] {
        height: 100vh;
        width: calc(100vw - (100vw - 100%));
    }
`

export const withGlobalStyles = (app: () => ReactNode) => {
    const GlobalStylesWrapper = () => (
        <>
            <Global styles={globalStyles} />
            {app()}
        </>
    )

    GlobalStylesWrapper.displayName = 'GlobalStylesWrapper'

    return GlobalStylesWrapper
}
