import { Button, Menu, MenuItem } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { useKeys } from './hooks'
import { Props } from './types'

export const PositionedMenu: FC<Props> = ({ text, onClick }) => {
    const { menuId, buttonId } = useKeys()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = ({ currentTarget }: MouseEvent<HTMLElement>) => {
        setAnchorEl(currentTarget)
    }
    const handleClose = () => {
        onClick()
        setAnchorEl(null)
    }

    return (
        <>
            <Button
                aria-controls={open ? menuId : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                id={buttonId}
                onClick={handleClick}
            >
                {text}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                aria-labelledby={buttonId}
                id={menuId}
                open={open}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
            >
                <MenuItem disabled>Предпросмотр активирован</MenuItem>
                <MenuItem onClick={handleClose}>Перейти</MenuItem>
            </Menu>
        </>
    )
}
