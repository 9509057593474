import { FC } from 'react'
import { Posts as PostList } from 'features/posts'
import { Wrapper } from './styled'
import { Props } from './types'

export const Posts: FC<Props> = () => (
    <Wrapper>
        <PostList />
    </Wrapper>
)
