import Typography from '@mui/material/Typography'
import { FC } from 'react'

export const PostDescription: FC = ({ children }) => (
    <Typography
        sx={{
            margin: '0px 0px 40px',
            fontWeight: 700,
        }}
        variant='h6'
    >
        {children}
    </Typography>
)
