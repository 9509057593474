import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
    Avatar,
    Box,
    Checkbox,
    Chip,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material'

import type { MouseEvent, VFC } from 'react'
import type { GetUsers } from 'features/users-table/model/__generated__/GetUsers'

type Props = {
    data: GetUsers['users'][0]
    selected?: boolean
    onClick?: (
        event: MouseEvent<HTMLButtonElement>,
        data: GetUsers['users'][0],
    ) => void
}

export const UsersTableRow: VFC<Props> = ({ data, selected, onClick }) => {
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        onClick && onClick(event, data)
    }

    const labelId = `users-checkbox-item-${data.uid}`

    return (
        <TableRow
            key={data.uid}
            hover
            aria-checked={selected}
            role='checkbox'
            selected={selected}
            tabIndex={-1}
        >
            <TableCell padding='checkbox'>
                <Checkbox
                    checked={selected}
                    color='primary'
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                    onClick={handleClick}
                />
            </TableCell>
            <TableCell align='left' component='th' id={labelId} scope='row'>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        src={data.profile?.avatarUrl ?? ''}
                        sx={{
                            width: 40,
                            height: 40,
                            marginRight: '16px',
                        }}
                    />
                    <Box>
                        <Typography
                            sx={{ fontWeight: 600 }}
                            variant='subtitle2'
                        >
                            {data.profile?.publicName ??
                                'Публичное имя не установлено'}
                        </Typography>
                        <Typography variant='caption'>{`@${data.login}`}</Typography>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align='left'>{data.profile?.email ?? '-'}</TableCell>
            <TableCell align='left'>
                <Stack direction='row' spacing={1}>
                    {data.userGroups.map((group) => (
                        <Chip key={group.uid} label={group.name} size='small' />
                    ))}
                </Stack>
            </TableCell>
            <TableCell align='left'>Подписки не реализованы</TableCell>
            <TableCell align='left'>
                <Chip
                    color='success'
                    label='Активен'
                    size='small'
                    variant='outlined'
                />
            </TableCell>
            <TableCell align='left'>
                <IconButton disabled>
                    <MoreVertIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
