import styled from '@emotion/styled'
import { Close } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { VFC } from 'react'
import { GridAria } from 'shared/ui/grid'
import { ComponentGrid } from '../component-grid'
import { Props } from './types'

const CloseButtonArea = styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
`

export const ComponentSkeleton: VFC<Props> = ({
    type,
    previewUrl,
    title,
    description,
    onClick,
}) => (
    <ComponentGrid type={type}>
        <CloseButtonArea>
            <IconButton color='primary' onClick={onClick}>
                <Close />
            </IconButton>
        </CloseButtonArea>
        <GridAria
            area='preview'
            style={type === 'center' ? { display: 'none' } : {}}
        >
            <img
                alt={previewUrl}
                loading='lazy'
                src={previewUrl}
                style={{ width: '100%', height: '100%' }}
            />
        </GridAria>
        <GridAria area='title'>
            <Typography variant='h6'>{title}</Typography>
            <Typography component='p' variant='body2'>
                {description}
            </Typography>
        </GridAria>
    </ComponentGrid>
)
