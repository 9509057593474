import { CircularProgress, List, Typography } from '@mui/material'
import { useNavigate } from '@reach/router'
import { FC } from 'react'
import { DraftListItem, useGetDraftsQuery } from 'entities/drafts'
import { TitleContent } from 'shared/ui/title-content'
import { Props } from './types'

export const Drafts: FC<Props> = () => {
    const { loading, data, error } = useGetDraftsQuery({})
    const navigate = useNavigate()

    if (loading) {
        return <CircularProgress />
    }
    if (error) {
        return <Typography variant='h4'>Ошибка: {error.message}</Typography>
    }

    return (
        <TitleContent
            title='Список черновиков'
            onCreate={() => navigate(`/drafts/create`)}
        >
            {!data && (
                <Typography variant='h4'>
                    Данных для отображения пока нет.
                </Typography>
            )}
            <List>
                {data?.drafts.items.map(({ uid, description, title }) => (
                    <DraftListItem
                        key={uid}
                        description={description}
                        title={title}
                    />
                ))}
            </List>
        </TitleContent>
    )
}
