import type { Components } from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism'

export const Code: Components['code'] = ({
    node,
    inline,
    className,
    children,
    ...props
}) => {
    const match = /language-(\w+)/.exec(className || '')
    return match ? (
        <SyntaxHighlighter
            PreTag='div'
            children={String(children).replace(/\n$/, '')}
            language={match[1]}
            style={materialOceanic as any}
            {...props}
        />
    ) : (
        <code className={className} {...props}>
            {children}
        </code>
    )
}
