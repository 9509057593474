import { yupResolver } from '@hookform/resolvers/yup'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    TextField,
} from '@mui/material'
import { VFC } from 'react'
import { useForm } from 'react-hook-form'
import { SignInInput } from '__generated__/types'
import { validationSchema } from '../lib'
import { StyledForm } from './styles'

type SignInFormProps = {
    onSubmit: (payload: SignInInput) => void
}

export const SignInForm: VFC<SignInFormProps> = ({ onSubmit }) => {
    const { register, handleSubmit, formState } = useForm<SignInInput>({
        resolver: yupResolver(validationSchema),
    })

    return (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Box>
                    <CardHeader title='РАЗРАБЫ' />

                    <CardContent>
                        <TextField
                            fullWidth
                            error={formState.errors.login !== undefined}
                            helperText={formState.errors.login?.message || ' '}
                            label='Логин'
                            variant='outlined'
                            {...register('login')}
                        />

                        <TextField
                            fullWidth
                            autoComplete='password'
                            error={formState.errors.password !== undefined}
                            helperText={
                                formState.errors.password?.message || ' '
                            }
                            label='Пароль'
                            type='password'
                            variant='outlined'
                            {...register('password')}
                        />

                        <Button
                            fullWidth
                            color='primary'
                            type='submit'
                            variant='contained'
                        >
                            Войти
                        </Button>
                    </CardContent>
                </Box>
            </Card>
        </StyledForm>
    )
}
