import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import type { Components } from 'react-markdown'

const MarkdownTypography = styled(Typography)`
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 16px;
`

export const H1: Components['h1'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '4rem' }}
        variant='h1'
        {...props}
    />
)

export const H2: Components['h2'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '3rem' }}
        variant='h2'
        {...props}
    />
)

export const H3: Components['h3'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '2rem' }}
        variant='h3'
        {...props}
    />
)

export const H4: Components['h4'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '1.5rem' }}
        variant='h4'
        {...props}
    />
)

export const H5: Components['h5'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '1.25rem' }}
        variant='h5'
        {...props}
    />
)

export const H6: Components['h6'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <MarkdownTypography
        children={children}
        sx={{ fontSize: '1.125rem' }}
        variant='h6'
        {...props}
    />
)

export const Paragraph: Components['p'] = ({
    node,
    className,
    children,
    ...props
}) => <Typography paragraph children={children} {...props} />
