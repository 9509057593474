import { Box, List, Pagination } from '@mui/material'
import { useState, VFC } from 'react'
import { SortType } from '__generated__/types'
import { useCreateComment } from 'features/post/model/__generated__/CreateComment'
import {
    GetCommentsByPostUid,
    GetCommentsByPostUidDocument,
    useGetCommentsByPostUid,
} from 'features/post/model/__generated__/GetCommentsByPostUid'
import { useRemoveComment } from 'features/post/model/__generated__/RemoveComment'

import { Comment, CommentForm, CommentSectionTitle } from './ui'
import type { InputComment } from './ui/comment-form'

type CommentType = GetCommentsByPostUid['comments']['items'][0]

type Props = {
    postUid: string
}

export const Comments: VFC<Props> = ({ postUid }) => {
    const [page, setPage] = useState(1)
    const [reply, setReply] = useState<CommentType | null>(null)
    const [createComment] = useCreateComment()
    const [removeComment] = useRemoveComment()

    const { data } = useGetCommentsByPostUid({
        variables: {
            postUid,
            page,
            perPage: 100,
            sort: {
                byCreationDate: SortType.Asc,
            },
        },
    })

    const handleChange = (
        _event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setPage(value)
    }

    const handleReply = (comment: CommentType) => {
        setReply(comment)
    }

    const handleRemoveReply = () => {
        setReply(null)
    }

    const handleSubmit = ({ content, replyingToCommentUid }: InputComment) => {
        setReply(null)
        createComment({
            variables: {
                data: {
                    content,
                    replyingToCommentUid,
                    postUid,
                },
            },
            update: (cache, response) => {
                cache.writeQuery({
                    query: GetCommentsByPostUidDocument,
                    variables: {
                        postUid,
                        page,
                        perPage: 100,
                        sort: {
                            byCreationDate: SortType.Asc,
                        },
                    },
                    data: {
                        comments: {
                            __typename: 'CommentPagination',
                            count: data!.comments.count + 1,
                            items: [
                                ...data!.comments.items,
                                response.data!.createComment,
                            ],
                        },
                    },
                })
            },
        })
    }

    const handleDelete = (comment: CommentType) => {
        removeComment({
            variables: {
                uid: comment.uid,
            },
            optimisticResponse: {
                removeComment: { uid: comment.uid },
            },
            update: (cache) => {
                cache.writeQuery({
                    query: GetCommentsByPostUidDocument,
                    variables: {
                        postUid,
                        page: 1,
                        perPage: 100,
                        sort: {
                            byCreationDate: SortType.Asc,
                        },
                    },
                    data: {
                        comments: {
                            __typename: 'CommentPagination',
                            count: data!.comments.count - 1,
                            items: data!.comments.items.filter(
                                (item) => item.uid !== comment.uid,
                            ),
                        },
                    },
                })
            },
        })
    }

    if (!data) {
        return null
    }

    return (
        <>
            <CommentSectionTitle>{data.comments.count}</CommentSectionTitle>
            <List>
                {data.comments.items.map((comment) => (
                    <Comment
                        key={comment.uid}
                        data={comment}
                        onDelete={handleDelete}
                        onReply={handleReply}
                    />
                ))}
            </List>
            <Box
                sx={{
                    marginBottom: '40px',
                    marginTop: '24px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {data.comments.count > 100 && (
                    <Pagination
                        color='primary'
                        count={Math.ceil(data.comments.count / 100)}
                        page={page}
                        onChange={handleChange}
                    />
                )}
            </Box>
            <CommentForm
                reply={reply}
                onClickRemoveReply={handleRemoveReply}
                onSubmit={handleSubmit}
            />
        </>
    )
}
