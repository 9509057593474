import { ListItemButton, ListItemText } from '@mui/material'
import { useNavigate } from '@reach/router'
import { useCallback, VFC } from 'react'
import type { NavbarLinkProps } from './types'

export const NavbarLink: VFC<NavbarLinkProps> = ({ text, url }) => {
    const navigate = useNavigate()

    const handleClick = useCallback(async () => {
        await navigate(url)
    }, [navigate, url])

    return (
        <ListItemButton onClick={handleClick}>
            <ListItemText primary={text} />
        </ListItemButton>
    )
}

export type { NavbarLinkProps }
