import styled from '@emotion/styled'
import { LinearProgress, Pagination, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState, VFC } from 'react'
import { PostsQuery, usePostsQueryLazyQuery } from '../../model'
import { PostItem } from '../post-item'
import { Props } from './types'

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const PostsList: VFC<Props> = ({ ariaControls, postUids, onClick }) => {
    const [pages, setPages] = useState(1)
    const [postsQuery, { data, loading }] = usePostsQueryLazyQuery()

    useEffect(() => {
        if (data?.posts.pages) {
            setPages(data.posts.pages)
        }
    }, [data?.posts.pages])

    useEffect(() => {
        postsQuery({ variables: { page: 1 } })
    }, [postsQuery])

    const onChange = (_: ChangeEvent<unknown>, page: number) => {
        postsQuery({ variables: { page } })
    }

    const currentFrontPage = (post: PostsQuery['posts']['items'][0]) => {
        if (postUids.includes(post.uid)) return true
        if (post.frontPages.length) return false
        return undefined
    }

    return (
        <FlexColumn>
            <Typography gutterBottom component='div' variant='h5'>
                Посты
            </Typography>

            <Pagination count={pages} onChange={onChange} />

            {loading && <LinearProgress />}

            {data?.posts.items.map((post) => (
                <PostItem
                    key={post.uid}
                    ariaControls={ariaControls}
                    currentFrontPage={currentFrontPage(post)}
                    post={post}
                    onClick={onClick(post)}
                />
            ))}
        </FlexColumn>
    )
}
