// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FrontPagesAllVariables = Types.Exact<{
  perPage: Types.Scalars['Int'];
  page: Types.Scalars['Int'];
}>;


export type FrontPagesAll = { frontPages: { count: number, pages: number, page: number, items: Array<{ uid: any, createdAt: any, updatedAt: any, title: string, publicationDate?: any | null, endDate?: any | null }> } };


export const FrontPagesAllDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FrontPagesAll"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"perPage"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"page"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"frontPages"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"perPage"},"value":{"kind":"Variable","name":{"kind":"Name","value":"perPage"}}},{"kind":"Argument","name":{"kind":"Name","value":"page"},"value":{"kind":"Variable","name":{"kind":"Name","value":"page"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"pages"}},{"kind":"Field","name":{"kind":"Name","value":"page"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"publicationDate"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useFrontPagesAll__
 *
 * To run a query within a React component, call `useFrontPagesAll` and pass it any options that fit your needs.
 * When your component renders, `useFrontPagesAll` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontPagesAll({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useFrontPagesAll(baseOptions: Apollo.QueryHookOptions<FrontPagesAll, FrontPagesAllVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FrontPagesAll, FrontPagesAllVariables>(FrontPagesAllDocument, options);
      }
export function useFrontPagesAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FrontPagesAll, FrontPagesAllVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FrontPagesAll, FrontPagesAllVariables>(FrontPagesAllDocument, options);
        }
export type FrontPagesAllHookResult = ReturnType<typeof useFrontPagesAll>;
export type FrontPagesAllLazyQueryHookResult = ReturnType<typeof useFrontPagesAllLazyQuery>;
export type FrontPagesAllQueryResult = Apollo.QueryResult<FrontPagesAll, FrontPagesAllVariables>;