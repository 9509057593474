import styled from '@emotion/styled'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import Typography from '@mui/material/Typography'

const Wrapper = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: 0;
    color: rgb(255, 255, 255);
    background-color: rgb(22, 28, 36);
    width: 100%;
    height: 100%;

    &:hover {
        z-index: 1;
        opacity: 0.72;
    }
`

export const Placeholder = () => (
    <Wrapper>
        <AddAPhotoIcon
            sx={{
                width: 24,
                height: 24,
                marginBottom: '8px',
            }}
        />
        <Typography sx={{ margin: 0 }} variant='caption'>
            Обновить фото
        </Typography>
    </Wrapper>
)
