import { RouteComponentProps, useNavigate } from '@reach/router'
import { useSnackbar } from 'notistack'
import { VFC } from 'react'
import { SignInInput } from '__generated__/types'
import { SignInForm, useSignIn } from 'features/sign-in'
import { Loader, UnauthorizedLayout } from 'shared/ui'

export const SignInPage: VFC<RouteComponentProps> = () => {
    const [signIn, { loading }] = useSignIn()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmit = async (payload: SignInInput) => {
        try {
            const { data } = await signIn({
                variables: { payload },
            })

            if (data?.signIn) {
                localStorage.setItem(
                    'access-token',
                    data.signIn.tokens.accessToken,
                )
                localStorage.setItem(
                    'refresh-token',
                    data.signIn.tokens.refreshToken,
                )
            }
            await navigate('/')
        } catch (e) {
            enqueueSnackbar('Что-то пошло не так.', { variant: 'error' })
        }
    }

    return (
        <UnauthorizedLayout>
            {loading ? <Loader /> : <SignInForm onSubmit={onSubmit} />}
        </UnauthorizedLayout>
    )
}
