import ShareIcon from '@mui/icons-material/Share'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, Stack, Typography } from '@mui/material'
import { VFC } from 'react'
import { PostQuery } from 'features/post/model/__generated__/PostQuery'

type Props = {
    data: PostQuery['post']
}

const formater = (n: number, digits: number) => {
    const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ]

    const regExp = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup
        .slice()
        .reverse()
        .find(({ value }) => n >= value)

    return item
        ? (n / item.value).toFixed(digits).replace(regExp, '$1') + item.symbol
        : '0'
}

export const Interactions: VFC<Props> = ({ data }) => (
    <Stack direction='row' spacing={1} sx={{ marginTop: '24px' }}>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <ShareIcon
                sx={{
                    padding: '8px',
                    widht: '24px',
                    height: '24px',
                    color: 'rgb(99, 115, 129)',
                }}
            />
            <Typography variant='body1'>
                {formater(data.interactions.sharesCount, 2)}
            </Typography>
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <VisibilityIcon
                sx={{
                    padding: '8px',
                    widht: '24px',
                    height: '24px',
                    color: 'rgb(99, 115, 129)',
                }}
            />
            <Typography variant='body1'>
                {formater(data.interactions.viewsCount, 2)}
            </Typography>
        </Box>
    </Stack>
)
