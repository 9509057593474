import styled from '@emotion/styled'
import { FC } from 'react'

const Background = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.palette.grey.A200};
`

export const UnauthorizedLayout: FC = ({ children }) => (
    <Background>{children}</Background>
)
