import { Menu, MenuItem } from '@mui/material'
import { VFC } from 'react'
import { useComponentsQuery } from '../../model'
import { Props } from './types'

export const ComponentsList: VFC<Props> = ({
    labelledby,
    anchorEl,
    onClick,
    handleClose,
}) => {
    const { data } = useComponentsQuery()

    return (
        <Menu
            anchorEl={anchorEl}
            aria-labelledby={labelledby}
            id='components-list'
            open={!!anchorEl}
            onClose={handleClose}
        >
            {data?.components.map((component) => (
                <MenuItem
                    key={component.uid}
                    onClick={() => onClick(component)}
                >
                    {component.name}
                </MenuItem>
            ))}
        </Menu>
    )
}
