import { LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { TitleContent } from 'shared/ui/title-content'
import { Wrapper } from '../createFeed/styled'
import { FeedsItem } from './feeds-item'
import { useFeeds } from './models/__generated__/Feeds'
import { StyledList } from './styled'
import { Props } from './types'

export const GetFeedsComponent: FC<Props> = () => {
    const { data, loading } = useFeeds({ fetchPolicy: 'no-cache' })

    return (
        <TitleContent title='Список лент'>
            <Wrapper>
                {loading && <LinearProgress />}

                {!data ? (
                    <Typography gutterBottom component='div' variant='h4'>
                        Данных для отображения пока нет
                    </Typography>
                ) : (
                    <StyledList>
                        {data.feeds?.map((feed) => (
                            <FeedsItem
                                key={feed.uid}
                                tags={feed.tags}
                                title={feed.name}
                                uid={feed.uid}
                            />
                        ))}
                    </StyledList>
                )}
            </Wrapper>
        </TitleContent>
    )
}
