import { ApolloError } from '@apollo/client'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { FC } from 'react'

export const ErrorAlert: FC<{ error: ApolloError }> = ({ error }) => (
    <Alert severity='error'>
        <AlertTitle>Error: {error.message}</AlertTitle>
        {error.extraInfo}
    </Alert>
)
