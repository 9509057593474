// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangePasswordVariables = Types.Exact<{
  data: Types.ChangePasswordInput;
}>;


export type ChangePassword = { changePassword: { uid: any } };


export const ChangePasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangePassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChangePasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changePassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]} as unknown as DocumentNode;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePassword, ChangePasswordVariables>;

/**
 * __useChangePassword__
 *
 * To run a mutation, you first call `useChangePassword` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePassword` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePassword, { data, loading, error }] = useChangePassword({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useChangePassword(baseOptions?: Apollo.MutationHookOptions<ChangePassword, ChangePasswordVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePassword, ChangePasswordVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordHookResult = ReturnType<typeof useChangePassword>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePassword>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePassword, ChangePasswordVariables>;