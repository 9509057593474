import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { ReactNode } from 'react'

const theme = createTheme()

export const withTheme = (app: () => ReactNode) => {
    const ThemeWrapper = () => (
        <ThemeProvider theme={theme}>{app()}</ThemeProvider>
    )

    ThemeWrapper.displayName = 'ThemeWrapper'

    return ThemeWrapper
}
