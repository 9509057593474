import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Link } from '@reach/router'
import { FC, SyntheticEvent } from 'react'
import { a11yProps } from 'features/front-page-manager/lib'

type Props = {
    onChange?: (event: SyntheticEvent<Element, Event>, value: number) => void
    value?: any
}

export const TabNavigator: FC<Props> = ({ onChange, value }) => (
    <Box
        sx={{
            marginTop: 4,
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
        }}
    >
        <>
            <Tabs
                aria-label='tabs of releases'
                value={value}
                onChange={onChange}
            >
                <Tab label='В работе' {...a11yProps(0)} />
                <Tab label='Недавно завершенные' {...a11yProps(1)} />
            </Tabs>
            <Button disableRipple component={Link} to='/front-pages/all/'>
                Показать все
            </Button>
        </>
    </Box>
)
