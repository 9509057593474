import AddIcon from '@mui/icons-material/Add'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useNavigate } from '@reach/router'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useState } from 'react'
import { FrontPageType } from '__generated__/types'
import { useCreateFrontPageMutation } from 'features/front-page-manager/model/__generated__/CreateFrontPageMutation'
import { FrontPagesDocument } from 'features/front-page-manager/model/__generated__/FrontPages'
import { ArchiveList } from './archive-list'
import { DEFAULT_FRONT_PAGES_QUERY_VARIABLES } from './constants'
import { DraftList } from './draft-list'
import { ErrorAlert } from './error-alert'
import { ReleaseCard } from './release-card'
import { TabNavigator } from './tab-navigator'
import { TabPanel } from './tab-panel'

const getTitleForCreatingRelease = () =>
    `Выпуск от ${format(new Date(), 'dd.MM.yyyy', {
        locale: ru,
    })}`

const variables = {
    data: {
        title: getTitleForCreatingRelease(),
    },
}

const refetchFrontPagesQuery = {
    query: FrontPagesDocument,
    variables: {
        ...DEFAULT_FRONT_PAGES_QUERY_VARIABLES,
        frontPageType: FrontPageType.Draft,
    },
}

export function FrontPageManager() {
    const navigate = useNavigate()
    const [createFrontPage, { loading, error }] = useCreateFrontPageMutation({})
    const [tabIndex, setTabIndex] = useState(0)

    const handleCreationClick = () => {
        createFrontPage({
            variables,
            refetchQueries: [refetchFrontPagesQuery],
        }).then(({ data }) => {
            navigate(`/front-pages/${data?.createFrontPage.uid}`)
        })
    }

    const handleTabChange = (_: any, value: number) => {
        setTabIndex(value)
    }

    return (
        <>
            {error && <ErrorAlert error={error} />}
            <Grid container spacing={4}>
                <Grid item>
                    <ReleaseCard />
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <Box
                        component={Paper}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '200px',
                            height: '200px',
                        }}
                    >
                        <Button
                            color='primary'
                            size='large'
                            sx={{
                                height: '64px',
                                width: '64px',
                                borderRadius: '50%',
                            }}
                            variant='contained'
                            onClick={handleCreationClick}
                        >
                            <AddIcon />
                        </Button>

                        <Typography
                            color='GrayText'
                            sx={{ textTransform: 'none', padding: '12px 0' }}
                            variant='body1'
                        >
                            Создать выпуск
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <TabNavigator value={tabIndex} onChange={handleTabChange} />
            <TabPanel index={0} value={tabIndex}>
                <DraftList />
            </TabPanel>
            <TabPanel index={1} value={tabIndex}>
                <ArchiveList />
            </TabPanel>
            <Backdrop
                open={loading}
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
        </>
    )
}
