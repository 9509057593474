import { Box, Typography } from '@mui/material'
import { FC } from 'react'

export const CommentSectionTitle: FC<{ children?: string | number }> = ({
    children,
}) => (
    <Box sx={{ marginBottom: '16px', display: 'flex' }}>
        <Typography sx={{ fontWeight: 700 }} variant='h5'>
            Комментарии
        </Typography>
        <Typography sx={{ color: 'rgb(145, 158, 171)' }} variant='subtitle2'>
            ({children})
        </Typography>
    </Box>
)
