// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FeedsVariables = Types.Exact<{ [key: string]: never; }>;


export type Feeds = { feeds: Array<{ uid: any, name: string, tags?: Array<{ uid: any, name: string }> | null }> };


export const FeedsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Feeds"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"feeds"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sort"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"byCreationDate"},"value":{"kind":"EnumValue","value":"desc"}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"tags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useFeeds__
 *
 * To run a query within a React component, call `useFeeds` and pass it any options that fit your needs.
 * When your component renders, `useFeeds` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeeds({
 *   variables: {
 *   },
 * });
 */
export function useFeeds(baseOptions?: Apollo.QueryHookOptions<Feeds, FeedsVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Feeds, FeedsVariables>(FeedsDocument, options);
      }
export function useFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Feeds, FeedsVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Feeds, FeedsVariables>(FeedsDocument, options);
        }
export type FeedsHookResult = ReturnType<typeof useFeeds>;
export type FeedsLazyQueryHookResult = ReturnType<typeof useFeedsLazyQuery>;
export type FeedsQueryResult = Apollo.QueryResult<Feeds, FeedsVariables>;