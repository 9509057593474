import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    // HttpLink,
    InMemoryCache,
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { ReactNode } from 'react'
import { env } from 'shared/config'

// const httpLink = new HttpLink({ uri: env.app.baseUrl })
const uploadLink = createUploadLink({ uri: env.app.baseUrl })

const requestLink = new ApolloLink((operation, forward) => {
    const accessToken = localStorage.getItem('access-token')

    operation.setContext({
        headers: {
            'authorization-token': accessToken ?? '',
        },
    })

    return forward(operation)
})

/*
const requestLink = createHttpLink({
    uri: '/gql',
    headers: {
        'authorization-token': token ?? '',
    },
})
*/

// Пример обработчика
// const authMiddleware = new ApolloLink((operation, forward) => {
//     operation.setContext(({ headers = {} }) => ({
//         headers: {
//             ...headers,
//             authorization: cookie.get('authorization'),
//         },
//     }))
//
//     return forward(operation)
// })

const client = new ApolloClient({
    link: requestLink.concat(uploadLink as unknown as ApolloLink),
    cache: new InMemoryCache(),
})

export const withApollo = (app: () => ReactNode) => {
    const ApolloWrapper = () => (
        <ApolloProvider client={client}>{app()}</ApolloProvider>
    )

    ApolloWrapper.displayName = 'ApolloWrapper'

    return ApolloWrapper
}
