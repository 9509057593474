import { Button, CardActions, CardHeader } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { Link } from '@reach/router'
import { FC } from 'react'
import { dateToLocaleRUUpdatedAt } from 'features/front-page-manager/lib'
import { useCurrentFrontPage } from 'features/front-page-manager/model/__generated__/CurrentFrontPage'
import { ErrorAlert } from './error-alert'

const formatPublicationDate = (publicationDate: string) =>
    `опубликован ${dateToLocaleRUUpdatedAt(publicationDate)}`

export const ReleaseCard: FC<{ title?: string }> = ({
    title = 'Текущий выпуск',
}) => {
    const { loading, data, error } = useCurrentFrontPage()

    if (error) {
        return <ErrorAlert error={error} />
    }

    return (
        <Card sx={{ width: 450 }}>
            <CardHeader
                subheader={
                    !loading && data ? (
                        formatPublicationDate(
                            data.currentFrontPage.publicationDate,
                        )
                    ) : (
                        <Skeleton />
                    )
                }
                title={title}
            />
            {loading ? (
                <Skeleton
                    animation='wave'
                    sx={{ height: 250 }}
                    variant='rectangular'
                />
            ) : (
                <CardMedia
                    alt='placeholder img'
                    component='img'
                    height='280'
                    src='https://pllsll.com/assets/tmp/images/62/3.png'
                />
            )}

            <CardContent>
                <Typography gutterBottom component='div' variant='h6'>
                    {!loading && data ? (
                        data.currentFrontPage.title
                    ) : (
                        <Skeleton />
                    )}
                </Typography>
                <Typography color='text.secondary' variant='body2'>
                    {!loading && data ? (
                        <>
                            Обновлён{' '}
                            {`${dateToLocaleRUUpdatedAt(
                                data.currentFrontPage.updatedAt,
                            )}`}
                        </>
                    ) : (
                        <Skeleton />
                    )}
                </Typography>
            </CardContent>

            <CardActions>
                {data && (
                    <Button
                        color='primary'
                        component={Link}
                        size='small'
                        to={`/front-pages/${data.currentFrontPage.uid}`}
                    >
                        Редактировать
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}
