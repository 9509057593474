import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { useState } from 'react'
import { usePostListQuery } from 'features/posts/model/__generated__/PostsQuery'
import { ErrorAlert } from './error-alert'
import { Post } from './post'

const PER_PAGE = 10
const DEFAULT_PAGE = 1

export const Posts = () => {
    const [page, setPage] = useState(DEFAULT_PAGE)
    const { loading, data, error } = usePostListQuery({
        variables: {
            page,
            perPage: PER_PAGE,
        },
    })

    const handleChangePage = (_: any, page: number) => {
        setPage(page)
        window.scrollTo(0, 0)
    }

    if (error) {
        return <ErrorAlert error={error} />
    }

    if (loading || !data) {
        return <CircularProgress />
    }

    return (
        <>
            <Stack
                component='ul'
                direction='column'
                divider={<Divider flexItem orientation='horizontal' />}
                spacing={4}
                sx={{
                    maxWidth: 720,
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                    marginBottom: '24px',
                }}
            >
                {data.posts.items.map((post) => (
                    <Post key={post.uid} data={post} />
                ))}
            </Stack>
            <Stack spacing={2}>
                <Pagination
                    count={data.posts.pages}
                    defaultPage={DEFAULT_PAGE}
                    page={page}
                    shape='rounded'
                    onChange={handleChangePage}
                />
            </Stack>
        </>
    )
}
