// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveCommentVariables = Types.Exact<{
  uid: Types.Scalars['UID'];
}>;


export type RemoveComment = { removeComment: { uid: any } };


export const RemoveCommentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveComment"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeComment"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]} as unknown as DocumentNode;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveComment, RemoveCommentVariables>;

/**
 * __useRemoveComment__
 *
 * To run a mutation, you first call `useRemoveComment` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveComment` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeComment, { data, loading, error }] = useRemoveComment({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useRemoveComment(baseOptions?: Apollo.MutationHookOptions<RemoveComment, RemoveCommentVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveComment, RemoveCommentVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentHookResult = ReturnType<typeof useRemoveComment>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveComment>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveComment, RemoveCommentVariables>;