import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const StyledButton = styled(Button)`
    justify-self: end;
    width: 120px;
`

export const ListItemButton = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    width: auto;
    min-height: 80px;
    border-radius: 8px;
    background-color: #e8eaf6;
    gap: 16px;
    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
    padding: 10px;
`

export const ListItemText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
