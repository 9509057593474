import { css } from '@emotion/react'

export const CardStyle = (color = 'transparent') => css`
    display: flex;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    cursor: default;
    background-color: ${color};
`

export const CardMediaStyle = {
    width: 170,
    display: { xs: 'none', sm: 'block' },
}

export const CardContentStyle = css`
    flex: 1;
`

export const TypographyStyle = (pointer: boolean) => css`
    cursor: ${pointer ? 'pointer' : 'default'};
    :hover {
        color: #1976d2;
    }
`
