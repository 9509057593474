import { Box, Tab, Tabs } from '@mui/material'
import React from 'react'
import { useGetCurrentUser } from 'features/user-profile/model/__generated__/GetCurrentUser'
import { UserChangePassword } from './user-change-password'
import { UserGeneralSettings } from './user-general-settings'

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props

    return (
        <div
            aria-labelledby={`user-profile-tabpanel-${index}`}
            hidden={value !== index}
            id={`user-profile-tabpanel-${index}`}
            role='tabpanel'
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: '24px 0' }}>{children}</Box>
            )}
        </div>
    )
}

export const UserProfile = () => {
    const { data } = useGetCurrentUser()
    const [value, setValue] = React.useState(0)

    const handleChange = (_: any, newValue: number) => {
        setValue(newValue)
    }

    if (!data) {
        return null
    }

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    aria-label='user tabs'
                    value={value}
                    onChange={handleChange}
                >
                    <Tab label='Общее' />
                    <Tab label='Изменить пароль' />
                </Tabs>
            </Box>
            <TabPanel index={0} value={value}>
                <UserGeneralSettings data={data.currentUser} />
            </TabPanel>
            <TabPanel index={1} value={value}>
                <UserChangePassword />
            </TabPanel>
        </Box>
    )
}
