import Typography from '@mui/material/Typography'
import type { FC } from 'react'

export const PostTitle: FC = ({ children }) => (
    <Typography
        sx={{
            fontWeight: 700,
            lineHeight: 1.33333,
            fontSize: '2rem',
            top: '0px',
            zIndex: 10,
            position: 'absolute',
            padding: '80px',
            color: 'rgb(255, 255, 255)',
            boxSizing: 'border-box',
        }}
        variant='h3'
    >
        {children}
    </Typography>
)
