/** @jsxImportSource @emotion/react */
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { VFC } from 'react'
import {
    CardContentStyle,
    CardMediaStyle,
    CardStyle,
    TypographyStyle,
} from './styles'
import { Props } from './types'

export const PostItem: VFC<Props> = ({
    ariaControls,
    post,
    currentFrontPage,
    onClick,
}) => {
    const getColor = (): string | undefined => {
        switch (currentFrontPage) {
            case true:
                return '#F5D3F2'
            case false:
                return '#CDF5CD'
            default:
                return undefined
        }
    }

    return (
        <Card css={CardStyle(getColor())}>
            <CardMedia
                alt={post.title}
                component='img'
                image={post.previewUrl ?? ''}
                sx={CardMediaStyle}
            />
            <CardContent css={CardContentStyle}>
                <Typography
                    aria-controls={ariaControls}
                    aria-haspopup='true'
                    component='h4'
                    css={TypographyStyle(!currentFrontPage)}
                    id={post.uid}
                    variant='h6'
                    onClick={!currentFrontPage ? onClick : undefined}
                >
                    {post.title}
                </Typography>
                <Typography component='p' variant='body2'>
                    {post.description}
                </Typography>
            </CardContent>
        </Card>
    )
}
