import { Chip, Stack } from '@mui/material'
import { VFC } from 'react'
import { PostQuery } from 'features/post/model/__generated__/PostQuery'

type Props = {
    data: {
        tags?: PostQuery['post']['tags']
    }
}

export const Tags: VFC<Props> = ({ data }) => (
    <Stack direction='row' spacing={1}>
        {(data.tags &&
            data.tags.map((tag) => <Chip key={tag.uid} label={tag.name} />)) ||
            'Не задано ни одного тэга'}
    </Stack>
)
