import styled from '@emotion/styled'

export const CenterCard = styled.div`
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template:
        '.' 1fr
        'title' auto
        'date' 1fr /
        1fr;
    justify-items: center;
    aspect-ratio: 4 / 1;
`
