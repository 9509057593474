import ClearIcon from '@mui/icons-material/Clear'
import SendIcon from '@mui/icons-material/Send'
import {
    Box,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material'
import { KeyboardEvent, SyntheticEvent, useState, VFC } from 'react'

type Reply = {
    uid: string
    content: string
}

export type InputComment = {
    content: string
    replyingToCommentUid?: string
}

export type CommentFormProps = {
    onSubmit?: (data: InputComment) => void
    reply?: Reply | null
    onClickRemoveReply?: (reply: Reply | {}) => void
}

export const CommentForm: VFC<CommentFormProps> = ({
    reply,
    onClickRemoveReply,
    onSubmit,
}) => {
    const [content, setContent] = useState<string>('')

    const handleChange = (
        event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.currentTarget) {
            setContent(event.currentTarget.value)
        }
    }

    const handleSubmit = () => {
        content &&
            onSubmit &&
            onSubmit({
                content: content.replace('\\n', '\n'),
                replyingToCommentUid: reply?.uid,
            })
        setContent('')
    }
    const handleClickRemoveReply = () => {
        reply && onClickRemoveReply && onClickRemoveReply(reply)
    }
    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            handleSubmit()
        }
    }

    return (
        <Box
            sx={{
                padding: '24px',
                background: 'rgb(244, 246, 248)',
            }}
        >
            {reply && (
                <ListItem
                    component='div'
                    sx={{
                        padding: '16px',
                        marginBottom: '16px',
                        background: 'rgba(0, 0, 0, .05)',
                    }}
                >
                    <ListItemText disableTypography>
                        <Typography component='blockquote' variant='body2'>
                            {reply.content.split('\n').map((line, index) => (
                                <span key={index} style={{ display: 'block' }}>
                                    {line}
                                </span>
                            ))}
                        </Typography>
                    </ListItemText>

                    <IconButton
                        aria-label='remove reply'
                        onClick={handleClickRemoveReply}
                    >
                        <ClearIcon />
                    </IconButton>
                </ListItem>
            )}
            <TextField
                fullWidth
                multiline
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='send comment'
                                type='submit'
                                onClick={handleSubmit}
                                onSubmit={handleSubmit}
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                label={reply ? 'Ответить' : 'Комментировать'}
                value={content}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        </Box>
    )
}
