// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserProfileVariables = Types.Exact<{
  avatar?: Types.InputMaybe<Types.Scalars['Upload']>;
  data: Types.ProfileUpdateInput;
  uid: Types.Scalars['UID'];
}>;


export type UpdateUserProfile = { updateProfile: { uid: any } };


export const UpdateUserProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUserProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"avatar"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Upload"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ProfileUpdateInput"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"uid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"avatar"},"value":{"kind":"Variable","name":{"kind":"Name","value":"avatar"}}},{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}},{"kind":"Argument","name":{"kind":"Name","value":"uid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"uid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfile, UpdateUserProfileVariables>;

/**
 * __useUpdateUserProfile__
 *
 * To run a mutation, you first call `useUpdateUserProfile` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfile` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfile, { data, loading, error }] = useUpdateUserProfile({
 *   variables: {
 *      avatar: // value for 'avatar'
 *      data: // value for 'data'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useUpdateUserProfile(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfile, UpdateUserProfileVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfile, UpdateUserProfileVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileHookResult = ReturnType<typeof useUpdateUserProfile>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfile>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfile, UpdateUserProfileVariables>;