import styled from '@emotion/styled'
import { List } from '@mui/material'

export const StyledList = styled(List)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    aria-labelledby='nested-list-subheader'
    component='nav'
    sx={{
        width: '100%',
        bgcolor: 'background.paper',
    }}

`
