import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import type { Components } from 'react-markdown'

const Overlay = styled.span`
    inset: 0px;
    line-height: 0;
    position: absolute;
    display: block;
    background-size: cover !important;
    filter: blur(0);
    transition: filter 0.3s;
`

const Wrapper = styled(Box)`
    width: 100%;
    line-height: 0;
    display: block;
    overflow: hidden;
    position: relative;
    padding-top: calc(56.25%);
    margin: 40px 0;
`

const Image = styled(Box)`
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
`

export const Img: Components['img'] = ({
    node,
    className,
    children,
    ...props
}) => (
    <Wrapper component='span'>
        <Overlay>
            <Image component='img' {...props} />
        </Overlay>
    </Wrapper>
)
