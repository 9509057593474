import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { Fragment } from 'react'
import { FrontPageItem, FrontPageType } from '__generated__/types'
import { useFrontPages } from 'features/front-page-manager/model/__generated__/FrontPages'
import { ArchiveListItem } from './archive-list-item'
import { DEFAULT_FRONT_PAGES_QUERY_VARIABLES } from './constants'
import { ErrorAlert } from './error-alert'
import { ListLoader } from './list-loader'

const renderWithDivider = (
    item: FrontPageItem,
    idx: number,
    arr: Array<FrontPageItem>,
) => (
    <Fragment key={item.uid}>
        <ArchiveListItem item={item} />
        {arr.length - 1 !== idx && <Divider component='li' variant='inset' />}
    </Fragment>
)

export function ArchiveList() {
    const { loading, data, error } = useFrontPages({
        variables: {
            ...DEFAULT_FRONT_PAGES_QUERY_VARIABLES,
            frontPageType: FrontPageType.Archive,
        },
    })

    if (error) {
        return <ErrorAlert error={error} />
    }

    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
        >
            {!loading && data ? (
                data.frontPages.items.map(renderWithDivider)
            ) : (
                <ListLoader />
            )}
        </List>
    )
}
