// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignInVariables = Types.Exact<{
  payload: Types.SignInInput;
}>;


export type SignIn = { signIn: { tokens: { refreshToken: string, accessToken: string } } };


export const SignInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SignIn"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"payload"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SignInInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"signIn"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"payload"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"tokens"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"accessToken"}}]}}]}}]}}]} as unknown as DocumentNode;
export type SignInMutationFn = Apollo.MutationFunction<SignIn, SignInVariables>;

/**
 * __useSignIn__
 *
 * To run a mutation, you first call `useSignIn` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignIn` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signIn, { data, loading, error }] = useSignIn({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSignIn(baseOptions?: Apollo.MutationHookOptions<SignIn, SignInVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignIn, SignInVariables>(SignInDocument, options);
      }
export type SignInHookResult = ReturnType<typeof useSignIn>;
export type SignInMutationResult = Apollo.MutationResult<SignIn>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignIn, SignInVariables>;