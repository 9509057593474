import { FC } from 'react'
import { UsersTable } from 'features/users-table'
import { Wrapper } from './styled'
import { Props } from './types'

export const Users: FC<Props> = () => (
    <Wrapper>
        <UsersTable />
    </Wrapper>
)
