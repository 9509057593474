import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Link } from '@reach/router'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { FC, useCallback, useState } from 'react'
import { PostListQuery } from 'features/posts/model/__generated__/PostsQuery'
import { env } from 'shared/config'

type Props = {
    data: PostListQuery['posts']['items'][0]
}

export const Post: FC<Props> = ({ data }) => {
    const [height, setHeight] = useState((720 / 16) * 9)
    const root = useCallback((node: Element) => {
        if (node !== null) {
            setHeight((node.getBoundingClientRect().width / 16) * 9)
        }
    }, [])

    return (
        <Box component='li' ref={root} sx={{ flexGrow: 1 }}>
            <Typography
                component={Link}
                sx={{
                    fontWeight: 700,
                    textDecoration: 'none',
                    color: 'inherit',
                    ':hover': {
                        textDecoration: 'underline',
                        color: (theme) => `${theme.palette.primary.main}`,
                    },
                }}
                to={`${data.uid}`}
                variant='subtitle1'
            >
                {data.title}
            </Typography>
            {data.tags && (
                <Stack direction='row' spacing={1} sx={{ margin: '8px 0' }}>
                    {data.tags.map((tag) => (
                        <Chip
                            key={tag.uid}
                            label={tag.name}
                            size='small'
                            sx={{ color: '#3E5060', fontWeight: 500 }}
                        />
                    ))}
                </Stack>
            )}

            {data.previewUrl && (
                <Box
                    sx={{
                        overflow: 'hidden',
                        width: '100%',
                        margin: '10px 0',
                    }}
                >
                    <img
                        alt={data.title}
                        src={data.previewUrl}
                        style={{
                            width: '100%',
                            maxHeight: `${height}px`,
                            objectFit: 'contain',
                            objectPosition: 'left top',
                        }}
                    />
                </Box>
            )}

            <Typography
                paragraph
                sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    color: '#3E5060',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'no-wrap',
                }}
                variant='body1'
            >
                {data.description}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Typography
                        sx={{ fontWeight: 500, margin: 0 }}
                        variant='body2'
                    >
                        {data?.githubAuthor?.user?.login
                            ? `Автор статьи: ${data?.githubAuthor?.user?.login}`
                            : 'Автор неизвестен'}
                    </Typography>
                    <Typography
                        sx={{ color: '#3E5060', fontWeight: 400 }}
                        variant='caption'
                    >
                        {format(parseISO(data.createdAt), 'PPPP', {
                            locale: ru,
                        })}
                    </Typography>
                </Box>
                <IconButton
                    component='a'
                    href={`${
                        env.app.postLink &&
                        env.app.postLink.replace(':uid', data.uid)
                    }`}
                    target='_blank'
                >
                    <OpenInNewIcon />
                </IconButton>
            </Box>
        </Box>
    )
}
