import {
    Alert,
    Box,
    Button,
    Card,
    FormControlLabel,
    Grid,
    Paper,
    Snackbar,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
    GetCurrentUser,
    GetCurrentUserDocument,
} from 'features/user-profile/model/__generated__/GetCurrentUser'
import { useUpdateUserProfile } from 'features/user-profile/model/__generated__/UpdateUserProfile'

import { AvatarCaption } from './avatar-caption'
import { AvatarUpload } from './avatar-upload'

type Props = {
    data: GetCurrentUser['currentUser']
}

export const UserGeneralSettings: FC<Props> = ({ data }) => {
    const [files, setFiles] = useState<null | FileList>(null)
    const [updateUserProfile, { loading, error }] = useUpdateUserProfile()
    const { register, handleSubmit } = useForm<{ publicName: string }>()
    const handleChange = (fileList: FileList) => {
        setFiles(fileList)
    }

    const onSubmit = (formData: { publicName: string }) => {
        updateUserProfile({
            variables: {
                avatar: (files as FileList)?.[0],
                uid: data.profile?.uid,
                data: {
                    publicName: formData.publicName,
                },
            },
            refetchQueries: [{ query: GetCurrentUserDocument }],
        }).catch(() => {
            setShowSnackbar(true)
        })
    }

    const [showSnackbar, setShowSnackbar] = useState(false)
    const handleCloseSnackbar = (_: any, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }
        setShowSnackbar(false)
    }

    return (
        <form>
            <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                    <Card
                        sx={{
                            padding: '80px 24px',
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <AvatarUpload
                            src={data.profile?.avatarUrl ?? ''}
                            onChange={handleChange}
                        />
                        <AvatarCaption />
                        <FormControlLabel
                            control={<Switch defaultChecked disabled />}
                            label={
                                <Typography variant='body1'>
                                    Публичный профиль
                                </Typography>
                            }
                            labelPlacement='start'
                            sx={{ marginTop: '40px' }}
                        />
                    </Card>
                </Grid>
                <Grid item md xs>
                    <Paper sx={{ padding: '24px' }}>
                        <Box
                            sx={{
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                display: 'grid',
                                gap: '24px 16px',
                            }}
                        >
                            <TextField
                                fullWidth
                                defaultValue={data.profile?.publicName}
                                {...register('publicName')}
                                label='Псевдноним/Публичное имя'
                                type='text'
                            />
                            <TextField
                                disabled
                                fullWidth
                                defaultValue={data.profile?.fullName}
                                label='Полное имя'
                                type='text'
                            />
                            <TextField
                                disabled
                                fullWidth
                                defaultValue={data.profile?.email}
                                label='Email'
                                type='email'
                            />
                            <TextField
                                disabled
                                fullWidth
                                defaultValue={data.login}
                                label='Логин'
                                type='text'
                            />
                        </Box>
                        <Box
                            component='div'
                            sx={{
                                marginTop: '24px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                disabled={loading}
                                onClick={handleSubmit(onSubmit)}
                            >
                                Сохранить изменения
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                open={!!error && showSnackbar}
                onClose={handleCloseSnackbar}
            >
                <Alert
                    severity='error'
                    sx={{ minWidth: '320px', width: '100%' }}
                >
                    Ошибка: {error?.message}
                </Alert>
            </Snackbar>
        </form>
    )
}
