// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatedFeedVariables = Types.Exact<{
  name: Types.Scalars['String'];
  tagUids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type CreatedFeed = { createFeed: { name: string, uid: any } };


export const CreatedFeedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CreatedFeed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"tagUids"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createFeed"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"tagUids"},"value":{"kind":"Variable","name":{"kind":"Name","value":"tagUids"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"uid"}}]}}]}}]} as unknown as DocumentNode;
export type CreatedFeedMutationFn = Apollo.MutationFunction<CreatedFeed, CreatedFeedVariables>;

/**
 * __useCreatedFeed__
 *
 * To run a mutation, you first call `useCreatedFeed` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatedFeed` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createdFeed, { data, loading, error }] = useCreatedFeed({
 *   variables: {
 *      name: // value for 'name'
 *      tagUids: // value for 'tagUids'
 *   },
 * });
 */
export function useCreatedFeed(baseOptions?: Apollo.MutationHookOptions<CreatedFeed, CreatedFeedVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatedFeed, CreatedFeedVariables>(CreatedFeedDocument, options);
      }
export type CreatedFeedHookResult = ReturnType<typeof useCreatedFeed>;
export type CreatedFeedMutationResult = Apollo.MutationResult<CreatedFeed>;
export type CreatedFeedMutationOptions = Apollo.BaseMutationOptions<CreatedFeed, CreatedFeedVariables>;