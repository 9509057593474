import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export const ListLoader = () => (
    <Box
        sx={{
            alignItems: 'center',
            minHeight: '64px',
            display: 'flex',
        }}
    >
        <CircularProgress />
    </Box>
)
