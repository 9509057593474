import EditIcon from '@mui/icons-material/Edit'
import Avatar from '@mui/material/Avatar'
import { green } from '@mui/material/colors'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Link } from '@reach/router'
import { FC } from 'react'
import { FrontPageItem } from '__generated__/types'
import { dateToLocaleRUUpdatedAt } from 'features/front-page-manager/lib'

export const DraftListItem: FC<{ item: FrontPageItem }> = ({
    item: { uid, updatedAt, title },
}) => (
    <ListItemButton disableGutters component={Link} to={`/front-pages/${uid}`}>
        <ListItem alignItems='flex-start'>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: green[500] }} variant='square'>
                    <EditIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={`Последнее обновление: ${dateToLocaleRUUpdatedAt(
                    updatedAt,
                )}`}
            />
        </ListItem>
    </ListItemButton>
)
