import FilterListIcon from '@mui/icons-material/FilterList'
import { IconButton, Toolbar, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
import type { VFC } from 'react'

type Props = {
    selectedCount: number
}

export const UsersTableToolbar: VFC<Props> = ({ selectedCount }) => (
    <Toolbar
        sx={{
            ...(selectedCount > 0 && {
                bgcolor: (theme) =>
                    alpha(
                        theme.palette.primary.main,
                        theme.palette.action.activatedOpacity,
                    ),
            }),
        }}
    >
        {selectedCount > 0 ? (
            <Typography
                color='inherit'
                component='div'
                sx={{ flex: '1 1 100%' }}
                variant='subtitle1'
            >
                Выбрано {selectedCount}
            </Typography>
        ) : (
            <Typography
                component='div'
                id='users-table-title'
                sx={{ flex: '1 1 100%' }}
                variant='h6'
            />
        )}
        {selectedCount <= 0 && (
            <IconButton disabled>
                <FilterListIcon />
            </IconButton>
        )}
    </Toolbar>
)
