import { LinearProgress } from '@mui/material'
import { useNavigate } from '@reach/router'
import { VFC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Tagging } from 'features/get-tags'
import { Inputs } from 'pages/feeds/createFeed/types'
import { TitleContent } from 'shared/ui/title-content'
import { useFeed } from '../createFeed/model/__generated__/Feed'
import { useUpdateFeed } from '../createFeed/model/__generated__/UpdatedFeed'
import { Wrapper } from '../createFeed/styled'
import { Props } from './types'

export const UpdateFeed: VFC<Props> = ({ feedUid }) => {
    const navigate = useNavigate()
    const [updateFeed] = useUpdateFeed()

    const { data: dataFeed, loading } = useFeed({
        fetchPolicy: 'no-cache',
        variables: { uid: feedUid || '' },
    })

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        updateFeed({
            variables: {
                name: data.name,
                tagUids: data.tagUids,
                uid: feedUid || '',
            },
        })
        navigate(`/feeds`)
    }

    return (
        <TitleContent title='Редактирование ленты'>
            <Wrapper>
                {loading && <LinearProgress />}
                {dataFeed && (
                    <Tagging
                        buttonText='Изменить'
                        defaultValue={dataFeed.feed.tags}
                        inputValue={dataFeed.feed.name}
                        onSubmit={onSubmit}
                    />
                )}
            </Wrapper>
        </TitleContent>
    )
}
