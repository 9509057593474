import styled from '@emotion/styled'
import Avatar from '@mui/material/Avatar'
import { FC } from 'react'

const Wrapper = styled(Avatar)`
    width: 100%;
    height: 100%;
    background-size: cover !important;
    filter: blur(0);
    transition: filter 0.3s;
    color: transparent;
`

type Props = {
    src?: string
}

export const AvatarImg: FC<Props> = ({ src }) => (
    <Wrapper>
        <Avatar
            alt='user-avatar'
            src={src}
            sx={{
                width: '126px',
                height: '126px',
            }}
        />
    </Wrapper>
)
