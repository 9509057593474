import styled from '@emotion/styled'
import type { Components } from 'react-markdown'

const Styl = styled.blockquote`
    line-height: 1.5;
    width: 80%;
    font-size: 1.5rem;
    margin: 40px auto;
    position: relative;
    font-family: Georgia, serif;
    padding: 24px 24px 24px 64px;
    border-radius: 16px;
    background-color: rgb(244, 246, 248);
    color: rgb(99, 115, 129) !important;
    & p {
        font-size: 1.5rem;
    }
    &:before {
        left: 16px;
        top: -8px;
        display: block;
        font-size: 5rem;
        content: '“';
        position: absolute;
        color: rgb(145, 158, 171);
    }
`

export const Blockquote: Components['blockquote'] = ({
    node,
    className,
    children,
    ...props
}) => <Styl children={children} {...props} />
