import styled from '@emotion/styled'

export const ColumnCard = styled.div`
    overflow: hidden;
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template:
        'preview' auto
        'title' auto
        'date' auto /
        1fr;
    gap: 8px;
`
