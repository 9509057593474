import BeenhereIcon from '@mui/icons-material/Beenhere'
import Avatar from '@mui/material/Avatar'
import { blue } from '@mui/material/colors'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { Link } from '@reach/router'
import { format, formatDistanceStrict, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { FC } from 'react'
import { FrontPageItem } from '__generated__/types'

const toLocaleDate = (date: string) =>
    format(parseISO(date), 'dd MMMM yyyy', { locale: ru })

const printActivePeriod = (baseDate: string, date: string) =>
    `Действовал ${formatDistanceStrict(parseISO(baseDate), parseISO(date), {
        locale: ru,
    })} с ${toLocaleDate(date)} по ${toLocaleDate(baseDate)}`

export const ArchiveListItem: FC<{ item: FrontPageItem }> = ({
    item: { uid, title, publicationDate, endDate },
}) => (
    <ListItemButton disableGutters component={Link} to={`/front-pages/${uid}`}>
        <ListItem alignItems='flex-start'>
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[500] }} variant='square'>
                    <BeenhereIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    publicationDate && endDate
                        ? printActivePeriod(publicationDate, endDate)
                        : ''
                }
            />
        </ListItem>
    </ListItemButton>
)
