import styled from '@emotion/styled'

export const StyledForm = styled.form`
    display: grid;
    grid-template:
        auto
        auto
        auto / auto;
    gap: 30px;
`
