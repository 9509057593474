import styled from '@emotion/styled'
import { FC, ReactElement } from 'react'

export const NAVBAR_WIDTH = 240

const OuterLayout = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: ${NAVBAR_WIDTH}px 1fr;
`

type AuthorizedLayoutProps = {
    navbar: ReactElement
}
export const AuthorizedLayout: FC<AuthorizedLayoutProps> = ({
    navbar,
    children,
}) => (
    <OuterLayout>
        {navbar}
        {children}
    </OuterLayout>
)
