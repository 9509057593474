import { useMemo } from 'react'

export const useKeys = () =>
    useMemo(() => {
        const rand = Math.random()
        return {
            buttonId: `${rand}-button`,
            menuId: `${rand}-menu`,
        }
    }, [])
